@charset "utf-8";
/*=================================
  visual
=================================*/
.mv {
  background: url(../img/top_mv_pc.jpg) no-repeat center center;
  background-size: cover;
  padding: 40px 1% 48px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
    padding: 0 52px;
    box-sizing: content-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }
  &__catch {
    font-size: 4.6rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.36;
    padding-right: 176px;
    .is-sm {
      font-size: 4rem;
    }
    .is-lg {
      font-size: 6.6rem;
    }
    .icon {
      &::after {
        content: "";
        width: 38px;
        height: 29px;
        display: inline-block;
        background: url(../img/icon/icon_comment.png) no-repeat center center;
        background-size: contain;
        vertical-align: middle;
        margin: -20px 15px 0;
      }
    }
  }
  &__media {
    width: 200px;
    background: #fff;
    border-radius: 50%;
    padding: 48px 0 72px;
    text-align: center;
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    .ttl {
      font-size: 1.8rem;
      font-weight: 700;
      line-height: 1;
      margin-bottom: 30px;
    }
    .img {
      width: 85%;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: none;
    padding: 0;
    &__inner {
      padding: 0;
      display: block;
    }
    &__catch {
      font-size: 2.6rem;
      line-height: 1.6;
      text-align: center;
      padding: per(30) per(17);
      background: url(../img/top_mv_sp.jpg) no-repeat center center;
      background-size: cover;
      .is-sm {
        font-size: 2.2rem;
      }
      .is-lg {
        font-size: 3.6rem;
      }
      .icon {
        &::after {
          width: 31px;
          height: 24px;
          margin: -15px 3px 0;
        }
      }
    }
    &__media {
      width: 100%;
      border-radius: 0;
      padding: per(12) 0;
      position: relative;
      transform: translateY(0);
      .ttl {
        font-size: 1.4rem;
        margin-bottom: 5px;
      }
      .img {
        width: 35%;
      }
    }
  }
}