@charset "utf-8";
/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  background: $color-theme;
  padding: 0 1%;
}
.cvBnr {
  max-width: $base-width;
  margin: 0 auto;
  padding: 22px 0 29px;
  position: relative;
  &::after {
    content: "";
    width: 274px;
    height: 299px;
    display: inline-block;
    background: url(../img/cvBnr_img.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 10px;
    bottom: 0;
    @include mq-tab {
      width: vw(260);
      height: vw(285);
      right: 0;
    }
  }
  &__ttl {
    font-size: 2.4rem;
    font-weight: 400;
    line-height: 1.33333;
    letter-spacing: .05em;
    color: #fff;
    text-align: center;
    margin: 0 0 26px;
  }
  .cvBtn {
    background: $color-yellow;
    box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
    border-radius: 20px;
    max-width: 570px;
    padding: 13px 22px 20px 22px;
    margin: 0 auto;
    position: relative;
    &::after {
      content: "";
      width: 30px;
      height: 30px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: 22px;
      transform: translateY(-50%);
    }
    &__text {
      font-size: 2rem;
      font-weight: 700;
      line-height: 2;
      display: block;
    }
    &__link {
      font-size: 4rem;
      font-weight: 700;
      line-height: 1;
      color: $color-blue-dark;
      text-align: center;
      text-decoration: none;
      display: block;
    }
    @include mq-desktop {
      &:hover {
        animation: jump .5s ease-in-out;
      }
    }
    @include mq-sp {
      animation: jumpSp 5s linear infinite;
    }
  }
  &.is--sm {
    padding: 0;
    margin: 36px 0;
    &::after {
      content: none;
    }
    .cvBtn {
      max-width: 640px;
      padding: 16px 70px 18px 160px;
      &__link {
        font-size: 2.8rem;
        text-align: left;
      }
      &::after {
        right: 39px;
      }
      &::before {
        content: "";
        width: 119px;
        height: 129px;
        display: inline-block;
        background: url(../img/cvBnr_img.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    padding: per(16) per(17);
    width: 100%;
  }
  .cvBnr {
    padding: 0;
    &::after {
      width: 114px;
      height: 124px;
      right: per(-15);
      bottom: initial;
      top: -45px;
      z-index: 1;
    }
    &__ttl {
      font-size: 1.8rem;
      font-weight: 600;
      line-height: 1.5555;
      text-align: left;
      margin: 0 0 9px;
    }
    .cvBtn {
      border-radius: 10px;
      max-width: initial;
      padding: per(15) per(35) per(17) per(13);
      z-index: 2;
      &::after {
        width: 24px;
        height: 24px;
        right: per(12);
      }
      &__text {
        font-size: 1.8rem;
        line-height: 1.1111;
        margin: 0 0 10px;
      }
      &__link {
        font-size: 2.8rem;
        text-align: left;
      }
    }
    &.is--sm {
      margin: 30px 0;
      &::after {
        content: none;
      }
      .cvBtn {
        max-width: initial;
        padding: 16px 0;
        &__text {
          font-size: 1.4rem;
          margin: 0 0 4px;
          padding-left: 72px;
        }
        &__link {
          font-size: 1.5rem;
          padding-left: 72px;
        }
        &::after {
          right: 10px;
        }
        &::before {
          width: 74px;
          height: 81px;
        }
      }
    }
  }
}