@charset "UTF-8";


@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes vibrate {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(15deg);
  }
  50% {
    transform: rotate(-15deg);
  }
  75% {
    transform: rotate(15deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes vibrate02 {
  0% {
    transform: rotate(0deg) scale(1.2);
  }
  5% {
    transform: rotate(15deg) scale(1.2);
  }
  10% {
    transform: rotate(-15deg) scale(1.2);
  }
  15% {
    transform: rotate(15deg) scale(1.2);
  }
  20% {
    transform: rotate(0deg) scale(1);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}
@keyframes jump {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-15px);
  }
  50% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes jumpSp {
  90% {
    transform: translateY(0);
  }
  92% {
    transform: translateY(-10px);
  }
  94% {
    transform: translateY(0);
  }
  96% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes headerFade {
  0% {
    transform: translateY(0);
    opacity: 0;
  }
  50% {
    transform: translateY(-20px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes burger-show {
  0% {
    right: -50%;
  }
  100% {
    right: 0%;
  }
}
a:hover {
  outline: none;
}
a:active {
  outline: none;
}
a:focus {
  outline: none;
}

@media screen and (min-width: 767px) {
  /* =================================
    hover
  ================================= */
  a {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .widelink {
    transition-property: all;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
    cursor: pointer;
  }
  .widelink .widelink__cover {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__cover::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 55%;
    background: #EC5934;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__cover::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__menu {
    position: relative;
    overflow: hidden;
    display: block;
  }
  .widelink .widelink__menu::before {
    content: "詳しく見る";
    font-size: 1.3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 15px 9%;
    border: 1px solid #fff;
    border-radius: 50px;
    position: absolute;
    bottom: 12%;
    left: 50%;
    transform: translateX(-50%);
    z-index: 6;
    transition-property: opacity, bottom;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article {
    position: relative;
    overflow: hidden;
  }
  .widelink .widelink__article::before {
    content: "詳しく見る";
    font-size: 1.4rem;
    color: #fff;
    border: 1px solid #fff;
    letter-spacing: 0.06em;
    line-height: 1;
    text-align: center;
    opacity: 0;
    padding: 10px 0;
    width: 45%;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 6;
    transition-property: opacity, top;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink .widelink__article::after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 5;
    opacity: 0;
    background-color: rgba(31, 31, 31, 0.5);
    color: white;
    transition-property: opacity;
    transition-duration: 0.15s;
    transition-timing-function: ease-out;
  }
  .widelink:hover a {
    text-decoration: none;
  }
  .widelink:hover .widelink__cover::before, .widelink:hover .widelink__article::before {
    opacity: 1;
    top: 50%;
  }
  .widelink:hover .widelink__cover::after, .widelink:hover .widelink__article::after {
    opacity: 1;
  }
  .widelink:hover .widelink__menu::before {
    opacity: 1;
    bottom: 10%;
  }
  .hoverBg .widelink__cover::before {
    content: none;
  }
  .hoverBg .widelink__cover::after {
    content: none;
  }
  .hoverBg:hover {
    background: #FFEAD7;
  }
}
/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
}
.btnWrap.center {
  text-align: center;
}
.btnWrap.right {
  text-align: right;
}
.btnWrap > * + * {
  margin-top: 9px;
}
.btnWrap.orange {
  background: #EC5934;
  padding: 30px 4.358974359% 20px;
}

.btn--yellow {
  font-size: 1.8rem;
  font-weight: 700;
  color: #1255FF;
  text-decoration: none;
  background: #FFE043;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
  width: 100%;
  text-align: center;
  padding: 20px 4.358974359%;
  margin: 0 auto;
  display: block;
  position: relative;
}
.btn--yellow::after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .btn--yellow:hover {
    animation: jump 0.5s ease-in-out;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .btn--yellow {
    animation: jumpSp 5s linear infinite;
  }
}

@media screen and (min-width: 767px) {
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    margin: 50px 0;
  }
  .btnWrap > * + * {
    margin: 0 0 0 20px;
  }
  .btnWrap.orange {
    padding: 32px 1% 24px;
  }
  .btn--yellow {
    font-size: 3rem;
    border-radius: 20px;
    max-width: 535px;
    padding: 23px;
  }
  .btn--yellow::after {
    width: 30px;
    height: 30px;
    right: 33px;
  }
}
/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 56px;
}
.ttl--base.color {
  color: #EC5934;
}
.ttl--base:not(.left) {
  text-align: center;
}
.ttl--base.white {
  color: #fff;
}
.ttl--base.icon--ranking::before {
  content: "";
  width: 34px;
  height: 27px;
  display: inline-block;
  background: url(../img/icon/icon_crown.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px 0 0;
}
.ttl--base.icon--area::before {
  content: "";
  width: 23px;
  height: 32px;
  display: inline-block;
  background: url(../img/icon/icon_pin.png) no-repeat center center;
  background-size: contain;
  margin: 0 12px -4px 0;
}
.ttl--base.icon--category::before {
  content: "";
  width: 25px;
  height: 35px;
  display: inline-block;
  background: url(../img/icon/icon_kyutouki.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
.ttl--base.icon--faq::before {
  content: "";
  width: 27px;
  height: 27px;
  display: inline-block;
  background: url(../img/icon/icon_question.png) no-repeat center center;
  background-size: contain;
  margin: 0 7px -2px 0;
}
.ttl--base.icon--pickup::before {
  content: "";
  width: 26px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_pickup.png) no-repeat center center;
  background-size: contain;
  margin: 0 10px 0 0;
}

.ttl--page {
  padding: 86px 1%;
  color: #fff;
  background: #EC5934 url(../img/bg_pageTtl_01.jpg) no-repeat center center;
  background-size: cover;
}
.ttl--page__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.ttl--page .ttl {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1.7;
  text-align: center;
}

@media screen and (max-width: 767px) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2.2rem;
    line-height: 1.4545;
    margin-bottom: 28px;
  }
  .ttl--base.icon--ranking .icon {
    font-size: 2.4rem;
    display: block;
  }
  .ttl--base.icon--ranking .icon::before {
    content: "";
    width: 25px;
    height: 20px;
    display: inline-block;
    background: url(../img/icon/icon_crown.png) no-repeat center center;
    background-size: contain;
    margin: 0 5px 0 0;
  }
  .ttl--base.icon--ranking::before {
    content: none;
  }
  .ttl--base.icon--area::before {
    width: 19px;
    height: 26px;
    margin: 0 6px -4px 0;
  }
  .ttl--base.icon--category::before {
    width: 18px;
    height: 25px;
    margin: 0 9px -2px 0;
  }
  .ttl--base.icon--faq::before {
    width: 22px;
    height: 22px;
    margin: 0 7px -2px 0;
  }
  .ttl--base.icon--pickup::before {
    width: 17px;
    height: 13px;
    margin: 0 6px 0 0;
  }
  .ttl--page {
    padding: 40px 4.358974359%;
  }
  .ttl--page .ttl {
    font-size: 2.6rem;
    line-height: 1.5;
  }
}
/*=================================
  tab
=================================*/
.tabMenu {
  display: flex;
}
.tabMenu__item {
  font-size: 2.2rem;
  line-height: 1.363636;
  font-weight: 500;
  color: #222222;
  background: #F3F3F3;
  padding: 30px 24px;
  text-align: center;
  width: 100%;
}
.tabMenu__item:not(.active) {
  cursor: pointer;
}
.tabMenu__item.active {
  background: #EC5934;
  color: #fff;
}
@media screen and (min-width: 767px) {
  .tabMenu__item:hover:not(.active) {
    background: #FFEAD7;
  }
}

.tabItem {
  background: #fff;
  min-height: 190px;
}
.tabItem .tabList {
  display: none;
}
.tabItem .tabList.show {
  display: block !important;
}

@media screen and (max-width: 767px) {
  /*=================================
    tab
  =================================*/
  .tabMenu__item {
    font-size: 1.6rem;
    padding: 27px 4.358974359%;
  }
  .tabItem {
    min-height: inherit;
  }
}
/*=================================
  accordion
=================================*/
.js-accordion__btn {
  position: relative;
  cursor: pointer;
}

.js-accordion__item {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    accordion
  =================================*/
}
/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 30px;
  width: 100%;
}
table caption {
  font-size: 1.4rem;
  margin: 20px 0;
  text-align: left;
  caption-side: bottom;
}
table th {
  font-size: 1.6rem;
  font-weight: 600;
  text-align: center;
  background: #FFF3E8;
  border-top: 1px solid #EC5934;
  border-bottom: 1px solid #EC5934;
}
table td {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.25;
  background: #fff;
  border-top: 1px solid #EC5934;
  border-bottom: 1px solid #EC5934;
}
table th, table td {
  padding: 19px 30px;
}
table.scroll {
  white-space: nowrap;
  overflow: auto;
  display: block;
  border: none;
}
table.scroll ::-webkit-scrollbar {
  height: 5px;
}
table.scroll ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
table.scroll ::-webkit-scrollbar-thumb {
  background: #bcbcbc;
}

.scrollAnnounce {
  display: none;
}

@media screen and (max-width: 767px) {
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
    /* スクロールのつまみ部分の設定 */
  }
  table caption {
    font-size: 1.5rem;
    margin: 15px 0;
    width: 94vw;
  }
  table th {
    font-size: 1.5rem;
    padding: 10px;
  }
  table td {
    font-size: 1.5rem;
    padding: 10px;
  }
  table::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  table::-webkit-scrollbar-track {
    border-radius: none;
    background: #d6d6d6;
  }
  table::-webkit-scrollbar-thumb {
    background: #FFE043;
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    margin: 20px 0;
  }
  .scrollAnnounce .text {
    font-size: 1.3rem;
    font-weight: 600;
    color: #fff;
    background: #EC5934;
    padding: 3px 6px;
    position: relative;
  }
  .scrollAnnounce .text::after {
    content: "";
    border-style: solid;
    border-width: 7px 6.5px 0 6.5px;
    border-color: #EC5934 transparent transparent transparent;
    display: inline-block;
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
  }
}
/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  background: #fff;
}
.breadcrumb ul li {
  font-size: 1.2rem;
  line-height: 1;
  display: inline-block;
  position: relative;
}
.breadcrumb ul li a, .breadcrumb ul li span {
  font-size: 1.2rem;
  color: #222222;
  line-height: 1;
  display: block;
  padding: 19px 0;
}
.breadcrumb ul li + li {
  padding-left: 20px;
}
.breadcrumb ul li + li::before {
  content: "";
  width: 6px;
  height: 9px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_o.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 51%;
  left: 6px;
  transform: translateY(-50%);
}

@media screen and (max-width: 767px) {
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 4.358974359% 15px;
  }
  .breadcrumb ul li a, .breadcrumb ul li span {
    padding: 8px 6px 10px;
  }
  .breadcrumb ul li + li {
    padding-left: 15px;
  }
  .breadcrumb ul li + li::before {
    content: "";
    width: 6px;
    height: 9px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_o.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 51%;
    left: 4px;
    transform: translateY(-50%);
  }
}
/*=================================
  pager
=================================*/
.pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pager .result {
  font-size: 1.6rem;
  font-weight: 500;
}
.pager .result .num {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #EC5934;
}
.pagerList {
  display: flex;
}
.pagerList__item {
  margin-right: 7px;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
  overflow: hidden;
}
.pagerList__item a, .pagerList__item span {
  font-size: 1.6rem;
  line-height: 1;
  padding: 14px 16px;
  display: block;
}
@media screen and (min-width: 767px) {
  .pagerList__item a:hover {
    color: #EC5934;
    background: #FFEAD7;
  }
}
.pagerList__item span {
  color: #EC5934;
  background: #FFEAD7;
}

@media screen and (max-width: 767px) {
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
  }
  .pager .result {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .pagerList {
    justify-content: center;
  }
  .pagerList__item {
    margin-right: 8px;
  }
  .pagerList__item a, .pagerList__item span {
    padding: 11px 14px;
  }
}
/*=================================
  topPage
=================================*/
.home .secWrap {
  padding: 90px 1% 60px;
}
.home .secWrap__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.home .category {
  background: #EC5934;
}
.home .categoryList {
  background: #FFEAD7;
  border-radius: 10px;
  padding: 40px 40px 3px;
  display: flex;
  flex-wrap: wrap;
}
.home .categoryList__item {
  width: calc((100% - 40px) / 3);
  margin-bottom: 37px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}
.home .categoryList__item:not(:nth-child(3n+1)) {
  margin-left: 20px;
}
.home .categoryList__item::after {
  content: "";
  width: 27px;
  height: 28px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .home .categoryList__item:hover {
    opacity: 0.8;
  }
  .home .categoryList__item:hover::after {
    right: 10px;
  }
}
.home .categoryList__link {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5416;
  color: #222222;
  text-shadow: 0 1px 2px #fff;
  text-decoration: none;
  display: block;
  width: 100%;
  padding: 0 44px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home .categoryList__bg {
  display: block;
}
.home .category .keySearch {
  margin: -26px 0 30px;
}
.home .category .keySearch .searchBox {
  position: relative;
  border: none;
}
.home .category .keySearch .searchBox .input {
  width: 100%;
  padding: 17px 20px;
  border-radius: 10px;
  background: #F5F5F5;
}
.home .category .keySearch .searchBox .input::placeholder {
  font-size: 2rem;
  line-height: 1;
  letter-spacing: 0;
  color: #444;
  background: url(../img/icon/icon_search.png) no-repeat left center;
  background-size: auto 20px;
  padding: 0 0 3px 28px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.home .category .keySearch .searchBox .submit {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 40px;
  cursor: pointer;
}
.home .faq {
  background: url(../img/bg_faq_pc.png) no-repeat right bottom;
  background-size: cover;
  padding: 85px 1% 83px;
}
.home .faqList {
  background: #fff;
  padding: 37px 40px;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.1);
}
.home .faqList .q {
  font-size: 2.4rem;
  font-weight: 400;
  color: #EC5934;
  padding-left: 40px;
  position: relative;
}
.home .faqList .q::before {
  content: "Q";
  font-size: 1.5rem;
  font-weight: 400;
  color: #fff;
  background: #EC5934;
  border-radius: 50px;
  display: inline-block;
  width: 30px;
  line-height: 30px;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.home .faqList .a {
  font-size: 1.6rem;
  line-height: 1.6875;
  padding: 0 40px;
  margin-top: 18px;
}
.home .faqList .a p + p {
  margin-top: 26px;
}
.home .faqList .a a {
  padding-right: 17px;
  margin: 0 1rem;
  position: relative;
}
.home .faqList .a a::after {
  content: "";
  width: 17px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.home .faqList + .faqList {
  margin-top: 30px;
}
.home .column {
  background: #fff;
}
.home .columnList__item {
  border: 1px solid #ddd;
  border-top: 6px solid #EC5934;
}
.home .columnList__item + .columnList__item {
  margin-top: 20px;
}
.home .columnList .column__ttl {
  font-size: 2rem;
  font-weight: 700;
  padding: 24px 30px 17px;
  border-bottom: 1px solid #ddd;
}
.home .columnList .column__cont {
  padding: 28px 30px 35px;
}
.home .columnList .columnLink__item {
  padding-left: 49px;
  position: relative;
}
.home .columnList .columnLink__item::before {
  font-family: "Roboto", sans-serif;
  content: "Q";
  font-size: 1.5rem;
  font-weight: 700;
  color: #fff;
  background: #EC5934;
  border-radius: 50px;
  display: inline-block;
  width: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
}
.home .columnList .columnLink__item a {
  font-size: 1.6rem;
}
.home .columnList .columnLink__item + .columnLink__item {
  margin-top: 28px;
}

@media screen and (max-width: 767px) {
  /*=================================
    topPage
  =================================*/
  .home .secWrap {
    padding: 36px 4.358974359%;
  }
  .home .category {
    padding: 35px 0 0;
  }
  .home .categoryList {
    border-radius: 0;
    padding: 26px 8.7179487179% 30px;
    display: block;
  }
  .home .categoryList__item {
    width: 100%;
    margin-bottom: 0;
    border-radius: 8px;
  }
  .home .categoryList__item:not(:nth-child(3n+1)) {
    margin-left: 0;
  }
  .home .categoryList__item::after {
    width: 26px;
    height: 26px;
    right: 16px;
  }
  .home .categoryList__item + .categoryList__item {
    margin-top: 30px;
  }
  .home .category .keySearch {
    margin: -10px 0 30px;
    padding: 0 8.7179487179%;
  }
  .home .category .keySearch .searchBox .input {
    width: 100%;
    padding: 17px 20px;
    border-radius: 6px;
  }
  .home .category .keySearch .searchBox .input::placeholder {
    font-size: 1.6rem;
    background-size: auto 17px;
    padding: 0 0 6px 28px;
  }
  .home .faq {
    background: url(../img/bg_faq_sp.png) no-repeat left top;
    background-size: 100% auto;
    padding: 36px 4.358974359% 40px;
  }
  .home .faqList {
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .home .faqList .q {
    font-size: 2rem;
    line-height: 1.4;
  }
  .home .faqList .q::before {
    font-size: 1.4rem;
    width: 25px;
    line-height: 25px;
    top: 2px;
    transform: translateY(0);
  }
  .home .faqList .a {
    padding: 0;
    margin-top: 15px;
  }
  .home .faqList .a p + p {
    margin-top: 20px;
  }
  .home .faqList + .faqList {
    margin-top: 20px;
  }
  .home .column {
    background: #fff;
  }
  .home .columnList__item {
    border: 1px solid #ddd;
    border-top: 6px solid #EC5934;
  }
  .home .columnList__item + .columnList__item {
    margin-top: 20px;
  }
  .home .columnList .column__ttl {
    font-size: 2rem;
    font-weight: 700;
    padding: 24px 30px 17px;
    border-bottom: 1px solid #ddd;
  }
  .home .columnList .column__cont {
    padding: 28px 30px 35px;
  }
  .home .columnList .columnLink__item {
    padding-left: 49px;
    position: relative;
  }
  .home .columnList .columnLink__item::before {
    content: "Q";
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    background: #EC5934;
    border-radius: 50px;
    display: inline-block;
    width: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
  }
  .home .columnList .columnLink__item a {
    font-size: 1.6rem;
  }
  .home .columnList .columnLink__item + .columnLink__item {
    margin-top: 28px;
  }
}
/*=================================
  page--article
=================================*/
/* 記事一覧 */
.articleList__item {
  border-radius: 10px;
  overflow: hidden;
}
.articleList__item .itemWrap {
  display: flex;
  flex-wrap: wrap;
  background: #FFEFE1;
}
.articleList__item .itemWrap .thumb {
  width: 392px;
}
.articleList__item .itemWrap .thumb img {
  margin: 0 auto;
  width: 100%;
}
.articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 392px);
}
.articleList__item .itemWrap .textWrap {
  width: 100%;
  padding: 24px 30px 16px 40px;
  position: relative;
}
.articleList__item .itemWrap .textWrap__date {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1;
  color: #EC5934;
  display: block;
  margin-bottom: 7px;
}
.articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.9rem;
  font-weight: 400;
  line-height: 1.31578;
  display: block;
  margin-bottom: 14px;
}
.articleList__item .itemWrap .textWrap__text {
  font-size: 1.6rem;
  line-height: 1.5625;
  margin-top: 14px;
  padding-bottom: 23px;
}
.articleList__item .itemWrap .moreBtn {
  width: 100%;
  text-align: right;
  position: absolute;
  right: 30px;
  bottom: 18px;
}
.articleList__item .itemWrap .moreBtn .more {
  font-size: 1.6rem;
  font-weight: 500;
  color: #1255FF;
  text-decoration: underline;
  display: inline-block;
}
.articleList__item .itemWrap .moreBtn .more::after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: 0 0 4px 6px;
}
@media screen and (min-width: 767px) {
  .articleList__item:hover .itemWrap .textWrap__ttl {
    color: #EC5934;
  }
  .articleList__item:hover .moreBtn .more {
    color: #EC5934;
    text-decoration: none;
  }
}
.articleList__item + .articleList__item {
  margin-top: 30px;
}
.articleList.col--2 {
  display: flex;
  flex-wrap: wrap;
}
.articleList.col--2 .articleList__item {
  border-radius: 0;
  border-bottom: 1px solid #D2D2D2;
  padding-bottom: 37px;
  margin-bottom: 40px;
  width: calc((100% - 30px) / 2);
}
.articleList.col--2 .articleList__item .itemWrap {
  align-items: flex-start;
  background: transparent;
}
.articleList.col--2 .articleList__item .itemWrap .thumb {
  width: 200px;
  margin-right: 30px;
}
.articleList.col--2 .articleList__item .itemWrap .thumb + .textWrap {
  width: calc(100% - 230px);
}
.articleList.col--2 .articleList__item .itemWrap .textWrap {
  padding: 0;
}
.articleList.col--2 .articleList__item .itemWrap .textWrap__date {
  font-size: 1.2rem;
}
.articleList.col--2 .articleList__item .itemWrap .textWrap__ttl {
  font-size: 1.7rem;
  line-height: 1.4705;
  margin-bottom: 2px;
}
.articleList.col--2 .articleList__item .itemWrap .textWrap__text {
  font-size: 1.5rem;
  line-height: 1.66666;
  margin-top: 2px;
  padding-bottom: 0;
}
@media screen and (min-width: 767px) {
  .articleList.col--2 .articleList__item:hover .itemWrap .textWrap__ttl {
    color: #EC5934;
  }
  .articleList.col--2 .articleList__item:hover .moreBtn .more {
    color: #EC5934;
    text-decoration: none;
  }
}
.articleList.col--2 .articleList__item + .articleList__item {
  margin-top: 0;
}
.articleList.col--2 .articleList__item:nth-child(2n) {
  margin-left: 30px;
}

.articleListWrap {
  padding: 30px 1% 0;
}
.articleListWrap__in {
  max-width: 1140px;
  margin: 0 auto;
  padding: 38px 70px 48px;
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
}
.articleListWrap__in h1, .articleListWrap__in h2, .articleListWrap__in h3, .articleListWrap__in h4, .articleListWrap__in h5, .articleListWrap__in h6 {
  clear: both;
}
.articleListWrap__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.6875;
}
.articleListWrap__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.articleListWrap__in h1:not([class]) {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  background: #EC5934;
  margin-top: 70px;
  margin-bottom: 40px;
  margin-left: -70px;
  padding: 32px 70px;
  width: calc(100% + 140px);
}
.articleListWrap__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.23529;
  margin-top: 70px;
  margin-bottom: 16px;
  padding: 7px 0 7px 14px;
  border-left: 4px solid #EC5934;
  position: relative;
}
.articleListWrap__in h3:not([class]) {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 15px;
  margin-top: 70px;
  margin-bottom: 30px;
  border-bottom: 2px solid #D2D2D2;
  position: relative;
}
.articleListWrap__in h3:not([class])::after {
  content: "";
  width: 25%;
  height: 2px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.articleListWrap__in h4:not([class]) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #EC5934;
  line-height: 1.66666;
  padding: 15px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.articleListWrap__in h5:not([class]) {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.44444;
  background: #F6F6F6;
  padding: 12px 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-left: 4px solid #EC5934;
}
.articleListWrap__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleListWrap__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  top: 8px;
}
.articleListWrap__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.articleListWrap__in blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.articleListWrap__in blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.articleListWrap__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleListWrap__in blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.articleListWrap__in blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.articleListWrap__in blockquote p + p {
  margin-top: 50px !important;
}
.articleListWrap__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}
.articleListWrap__in .btnWrap {
  clear: both;
}
.articleListWrap__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.articleListWrap__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleListWrap__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleListWrap__in > a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.articleListWrap__in ul:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  clear: both;
}
.articleListWrap__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 38px;
  position: relative;
}
.articleListWrap__in ul:not([class]) li::before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.articleListWrap__in ul:not([class]) li + li {
  margin-top: 20px;
}
.articleListWrap__in ul:not([class]) h6::before {
  content: none;
}
.articleListWrap__in ol:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  background: #FFEAD7;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.articleListWrap__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 34px;
  position: relative;
}
.articleListWrap__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 26px;
  line-height: 26px;
  text-align: center;
  background: #EC5934;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.articleListWrap__in ol:not([class]) li + li {
  margin-top: 20px;
}
.articleListWrap__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleListWrap__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.6875;
  }
  .articleListWrap__in p:not([class]) + p:not([class]) {
    margin-top: 20px;
  }
  .articleListWrap__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.3;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: calc(-4.358974359% - 2px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 4px);
  }
  .articleListWrap__in h2:not([class]) {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 4px 0 4px 12px;
  }
  .articleListWrap__in h3:not([class]) {
    font-size: 1.8rem;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-top: 50px;
  }
  .articleListWrap__in h4:not([class]) {
    font-size: 1.7rem;
    line-height: 1.3;
    padding: 10px 4.358974359%;
  }
  .articleListWrap__in h5:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 12px 4.358974359%;
    margin-top: 50px;
  }
  .articleListWrap__in h6:not([class]) {
    font-size: 1.7rem;
  }
  .articleListWrap__in blockquote {
    padding: 30px 4.358974359%;
  }
  .articleListWrap__in blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .articleListWrap__in blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .articleListWrap__in blockquote h1 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h2 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h3 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h4 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h5 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote h6 {
    font-size: 1.5rem;
  }
  .articleListWrap__in blockquote .text {
    padding-left: 4.358974359%;
  }
  .articleListWrap__in blockquote p {
    line-height: 2 !important;
  }
  .articleListWrap__in blockquote p + p {
    margin-top: 20px !important;
  }
  .articleListWrap__in blockquote cite {
    text-align: left;
  }
  .articleListWrap__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleListWrap__in ul:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .articleListWrap__in ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 28px;
  }
  .articleListWrap__in ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 3px;
  }
  .articleListWrap__in ul:not([class]) li + li {
    margin-top: 15px;
  }
  .articleListWrap__in ol:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .articleListWrap__in ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .articleListWrap__in ol:not([class]) li::before {
    width: 22px;
    line-height: 22px;
    top: 2px;
  }
  .articleListWrap__in ol:not([class]) li + li {
    margin-top: 15px;
  }
}
.articleListWrap__in iframe {
  width: 100%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleListWrap__in {
    padding: 2.7818448023vw 5.1244509517vw 3.513909224vw;
  }
}
.articleListWrap .articleList {
  margin: 44px 0 30px;
}

/* singlePage */
.singlePage {
  padding: 30px 1% 0;
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    日付
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    ポイント
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
}
.singlePage__in {
  max-width: 1140px;
  margin: 0 auto;
  padding: 38px 70px 48px;
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
}
.singlePage__in h1, .singlePage__in h2, .singlePage__in h3, .singlePage__in h4, .singlePage__in h5, .singlePage__in h6 {
  clear: both;
}
.singlePage__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.6875;
}
.singlePage__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.singlePage__in h1:not([class]) {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  background: #EC5934;
  margin-top: 70px;
  margin-bottom: 40px;
  margin-left: -70px;
  padding: 32px 70px;
  width: calc(100% + 140px);
}
.singlePage__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.23529;
  margin-top: 70px;
  margin-bottom: 16px;
  padding: 7px 0 7px 14px;
  border-left: 4px solid #EC5934;
  position: relative;
}
.singlePage__in h3:not([class]) {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 15px;
  margin-top: 70px;
  margin-bottom: 30px;
  border-bottom: 2px solid #D2D2D2;
  position: relative;
}
.singlePage__in h3:not([class])::after {
  content: "";
  width: 25%;
  height: 2px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.singlePage__in h4:not([class]) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #EC5934;
  line-height: 1.66666;
  padding: 15px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.singlePage__in h5:not([class]) {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.44444;
  background: #F6F6F6;
  padding: 12px 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-left: 4px solid #EC5934;
}
.singlePage__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.singlePage__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  top: 8px;
}
.singlePage__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.singlePage__in blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.singlePage__in blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.singlePage__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.singlePage__in blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.singlePage__in blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.singlePage__in blockquote p + p {
  margin-top: 50px !important;
}
.singlePage__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}
.singlePage__in .btnWrap {
  clear: both;
}
.singlePage__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.singlePage__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.singlePage__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.singlePage__in > a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.singlePage__in ul:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  clear: both;
}
.singlePage__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 38px;
  position: relative;
}
.singlePage__in ul:not([class]) li::before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.singlePage__in ul:not([class]) li + li {
  margin-top: 20px;
}
.singlePage__in ul:not([class]) h6::before {
  content: none;
}
.singlePage__in ol:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  background: #FFEAD7;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.singlePage__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 34px;
  position: relative;
}
.singlePage__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 26px;
  line-height: 26px;
  text-align: center;
  background: #EC5934;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage__in ol:not([class]) li + li {
  margin-top: 20px;
}
.singlePage__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.6875;
  }
  .singlePage__in p:not([class]) + p:not([class]) {
    margin-top: 20px;
  }
  .singlePage__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.3;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: calc(-4.358974359% - 2px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 4px);
  }
  .singlePage__in h2:not([class]) {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 4px 0 4px 12px;
  }
  .singlePage__in h3:not([class]) {
    font-size: 1.8rem;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-top: 50px;
  }
  .singlePage__in h4:not([class]) {
    font-size: 1.7rem;
    line-height: 1.3;
    padding: 10px 4.358974359%;
  }
  .singlePage__in h5:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 12px 4.358974359%;
    margin-top: 50px;
  }
  .singlePage__in h6:not([class]) {
    font-size: 1.7rem;
  }
  .singlePage__in blockquote {
    padding: 30px 4.358974359%;
  }
  .singlePage__in blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .singlePage__in blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .singlePage__in blockquote h1 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h2 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h3 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h4 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h5 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote h6 {
    font-size: 1.5rem;
  }
  .singlePage__in blockquote .text {
    padding-left: 4.358974359%;
  }
  .singlePage__in blockquote p {
    line-height: 2 !important;
  }
  .singlePage__in blockquote p + p {
    margin-top: 20px !important;
  }
  .singlePage__in blockquote cite {
    text-align: left;
  }
  .singlePage__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .singlePage__in ul:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .singlePage__in ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 28px;
  }
  .singlePage__in ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 3px;
  }
  .singlePage__in ul:not([class]) li + li {
    margin-top: 15px;
  }
  .singlePage__in ol:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .singlePage__in ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .singlePage__in ol:not([class]) li::before {
    width: 22px;
    line-height: 22px;
    top: 2px;
  }
  .singlePage__in ol:not([class]) li + li {
    margin-top: 15px;
  }
}
.singlePage__in iframe {
  width: 100%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .singlePage__in {
    padding: 2.7818448023vw 5.1244509517vw 3.513909224vw;
  }
}
.singlePage__in > h1:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h2:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h3:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h4:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h5:first-child {
  margin-top: 0 !important;
}
.singlePage__in > h6:first-child {
  margin-top: 0 !important;
}
.singlePage .articleTtl {
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.38888;
}
.singlePage .articleDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #EC5934;
  line-height: 1.46666;
  margin-bottom: 10px;
}
.singlePage .articleMv {
  margin: 18px auto 16px;
  text-align: center;
}
.singlePage .tocBox {
  border-radius: 10px;
  border: 1px solid #D2D2D2;
  margin: 32px 0 110px;
  padding: 27px 2.9282576867vw 30px;
}
.singlePage .tocBox__ttl {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 10px;
  text-align: center;
}
.singlePage .tocBox .tocList {
  counter-reset: tocNum;
}
.singlePage .tocBox .tocList__item {
  position: relative;
}
.singlePage .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.singlePage .tocBox .tocList__item a {
  font-size: 1.6rem;
  display: inline-block;
  padding: 0 0 0 24px;
}
.singlePage .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.singlePage .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.singlePage .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.singlePage .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
}
.singlePage .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.singlePage .point {
  padding: 37px 2.9282576867vw;
  background: #FFEAD7;
  border: 1px solid #EC5934;
  margin: 114px 0 30px;
  position: relative;
}
.singlePage .point__label {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #fff;
  background: #EC5934;
  padding: 14px 54px;
  position: absolute;
  top: -44px;
  left: -1px;
}
.singlePage .point__label::before {
  content: "";
  width: 15px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/icon_hand.png) no-repeat center center;
  background-size: contain;
  margin: 0 4px -2px 0;
}
.singlePage .point__cont p {
  padding-left: 38px;
  position: relative;
}
.singlePage .point__cont p::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/icon/icon_check_circle.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 4px;
  left: 0;
}
.singlePage .articleList {
  margin: 36px 0 67px;
}
.singlePage .articleList__item .itemWrap .thumb {
  position: relative;
}
.singlePage .articleList__item .itemWrap .thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/related_cover.png) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.singlePage .articleList__item + .articleList__item {
  margin-top: 15px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .singlePage {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      日付
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .singlePage .articleTtl {
    font-size: 2.2rem;
    line-height: 1.4;
  }
  .singlePage .articleDate {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .singlePage .articleMv {
    margin: 20px auto 30px;
  }
  .singlePage .tocBox {
    border-radius: 6px;
    margin: 50px 0 30px;
    padding: 30px 4.358974359%;
  }
  .singlePage .tocBox__ttl {
    font-size: 1.7rem;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  .singlePage .tocBox .tocList__item::before {
    font-size: 1.4rem;
  }
  .singlePage .tocBox .tocList__item a {
    font-size: 1.5rem;
    padding: 0 0 0 20px;
  }
  .singlePage .tocBox .tocList__item .tocList {
    padding-left: 20px;
    margin-top: 10px;
  }
  .singlePage .tocBox .tocList__item .tocList__item a {
    padding-left: 40px;
  }
}

/* 記事詳細ページ */
.articleDetail {
  padding: 30px 1% 0;
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    日付
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    ポイント
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
}
.articleDetail__in {
  max-width: 1140px;
  margin: 0 auto;
  padding: 38px 70px 48px;
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  position: relative;
}
.articleDetail__in h1, .articleDetail__in h2, .articleDetail__in h3, .articleDetail__in h4, .articleDetail__in h5, .articleDetail__in h6 {
  clear: both;
}
.articleDetail__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.6875;
}
.articleDetail__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.articleDetail__in h1:not([class]) {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  background: #EC5934;
  margin-top: 70px;
  margin-bottom: 40px;
  margin-left: -70px;
  padding: 32px 70px;
  width: calc(100% + 140px);
}
.articleDetail__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.23529;
  margin-top: 70px;
  margin-bottom: 16px;
  padding: 7px 0 7px 14px;
  border-left: 4px solid #EC5934;
  position: relative;
}
.articleDetail__in h3:not([class]) {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 15px;
  margin-top: 70px;
  margin-bottom: 30px;
  border-bottom: 2px solid #D2D2D2;
  position: relative;
}
.articleDetail__in h3:not([class])::after {
  content: "";
  width: 25%;
  height: 2px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.articleDetail__in h4:not([class]) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #EC5934;
  line-height: 1.66666;
  padding: 15px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.articleDetail__in h5:not([class]) {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.44444;
  background: #F6F6F6;
  padding: 12px 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-left: 4px solid #EC5934;
}
.articleDetail__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.articleDetail__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  top: 8px;
}
.articleDetail__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.articleDetail__in blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.articleDetail__in blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.articleDetail__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.articleDetail__in blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.articleDetail__in blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.articleDetail__in blockquote p + p {
  margin-top: 50px !important;
}
.articleDetail__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}
.articleDetail__in .btnWrap {
  clear: both;
}
.articleDetail__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.articleDetail__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.articleDetail__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.articleDetail__in > a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.articleDetail__in ul:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  clear: both;
}
.articleDetail__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 38px;
  position: relative;
}
.articleDetail__in ul:not([class]) li::before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.articleDetail__in ul:not([class]) li + li {
  margin-top: 20px;
}
.articleDetail__in ul:not([class]) h6::before {
  content: none;
}
.articleDetail__in ol:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  background: #FFEAD7;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.articleDetail__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 34px;
  position: relative;
}
.articleDetail__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 26px;
  line-height: 26px;
  text-align: center;
  background: #EC5934;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail__in ol:not([class]) li + li {
  margin-top: 20px;
}
.articleDetail__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.6875;
  }
  .articleDetail__in p:not([class]) + p:not([class]) {
    margin-top: 20px;
  }
  .articleDetail__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.3;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: calc(-4.358974359% - 2px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 4px);
  }
  .articleDetail__in h2:not([class]) {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 4px 0 4px 12px;
  }
  .articleDetail__in h3:not([class]) {
    font-size: 1.8rem;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-top: 50px;
  }
  .articleDetail__in h4:not([class]) {
    font-size: 1.7rem;
    line-height: 1.3;
    padding: 10px 4.358974359%;
  }
  .articleDetail__in h5:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 12px 4.358974359%;
    margin-top: 50px;
  }
  .articleDetail__in h6:not([class]) {
    font-size: 1.7rem;
  }
  .articleDetail__in blockquote {
    padding: 30px 4.358974359%;
  }
  .articleDetail__in blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .articleDetail__in blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .articleDetail__in blockquote h1 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote h2 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote h3 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote h4 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote h5 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote h6 {
    font-size: 1.5rem;
  }
  .articleDetail__in blockquote .text {
    padding-left: 4.358974359%;
  }
  .articleDetail__in blockquote p {
    line-height: 2 !important;
  }
  .articleDetail__in blockquote p + p {
    margin-top: 20px !important;
  }
  .articleDetail__in blockquote cite {
    text-align: left;
  }
  .articleDetail__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .articleDetail__in ul:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .articleDetail__in ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 28px;
  }
  .articleDetail__in ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 3px;
  }
  .articleDetail__in ul:not([class]) li + li {
    margin-top: 15px;
  }
  .articleDetail__in ol:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .articleDetail__in ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .articleDetail__in ol:not([class]) li::before {
    width: 22px;
    line-height: 22px;
    top: 2px;
  }
  .articleDetail__in ol:not([class]) li + li {
    margin-top: 15px;
  }
}
.articleDetail__in iframe {
  width: 100%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .articleDetail__in {
    padding: 2.7818448023vw 5.1244509517vw 3.513909224vw;
  }
}
.articleDetail__in .tocBox + * {
  margin-top: 0 !important;
}
.articleDetail .articleTtl {
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.38888;
}
.articleDetail .articleDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #EC5934;
  line-height: 1.46666;
  margin-bottom: 10px;
}
.articleDetail .articleMv {
  margin: 18px auto 16px;
  text-align: center;
}
.articleDetail .tocBox {
  border-radius: 10px;
  border: 1px solid #D2D2D2;
  margin: 32px 0 110px;
  padding: 27px 2.9282576867vw 30px;
}
.articleDetail .tocBox__ttl {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 10px;
  text-align: center;
}
.articleDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.articleDetail .tocBox .tocList__item {
  position: relative;
}
.articleDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.articleDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  display: inline-block;
  padding: 0 0 0 24px;
}
.articleDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.articleDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.articleDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.articleDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
}
.articleDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.articleDetail .point {
  padding: 37px 2.9282576867vw;
  background: #FFEAD7;
  border: 1px solid #EC5934;
  margin: 114px 0 30px;
  position: relative;
}
.articleDetail .point__label {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #fff;
  background: #EC5934;
  padding: 14px 54px;
  position: absolute;
  top: -44px;
  left: -1px;
}
.articleDetail .point__label::before {
  content: "";
  width: 15px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/icon_hand.png) no-repeat center center;
  background-size: contain;
  margin: 0 4px -2px 0;
}
.articleDetail .point__cont p {
  padding-left: 38px;
  position: relative;
}
.articleDetail .point__cont p::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/icon/icon_check_circle.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 4px;
  left: 0;
}
.articleDetail .articleList {
  margin: 36px 0 67px;
}
.articleDetail .articleList__item .itemWrap .thumb {
  position: relative;
}
.articleDetail .articleList__item .itemWrap .thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/related_cover.png) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.articleDetail .articleList__item + .articleList__item {
  margin-top: 15px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .articleDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      日付
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .articleDetail .articleTtl {
    font-size: 2.2rem;
    line-height: 1.4;
  }
  .articleDetail .articleDate {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .articleDetail .articleMv {
    margin: 20px auto 30px;
  }
  .articleDetail .tocBox {
    border-radius: 6px;
    margin: 50px 0 30px;
    padding: 30px 4.358974359%;
  }
  .articleDetail .tocBox__ttl {
    font-size: 1.7rem;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  .articleDetail .tocBox .tocList__item::before {
    font-size: 1.4rem;
  }
  .articleDetail .tocBox .tocList__item a {
    font-size: 1.5rem;
    padding: 0 0 0 20px;
  }
  .articleDetail .tocBox .tocList__item .tocList {
    padding-left: 20px;
    margin-top: 10px;
  }
  .articleDetail .tocBox .tocList__item .tocList__item a {
    padding-left: 40px;
  }
}

.blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.blockquote p + p {
  margin-top: 50px !important;
}
.blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}

@media screen and (max-width: 767px) {
  /* singlePage */
  .singlePage {
    padding: 20px 4.358974359% 47px;
  }
  .singlePage__in {
    padding: 20px 4.358974359% 47px;
  }
  /* 記事詳細ページ */
  .articleDetail {
    padding: 30px 4.358974359% 0;
  }
  .articleDetail__in {
    padding: 30px 4.358974359% 40px;
    border-radius: 6px;
  }
  .blockquote {
    padding: 30px 4.358974359%;
  }
  .blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .blockquote h1 {
    font-size: 1.5rem;
  }
  .blockquote h2 {
    font-size: 1.5rem;
  }
  .blockquote h3 {
    font-size: 1.5rem;
  }
  .blockquote h4 {
    font-size: 1.5rem;
  }
  .blockquote h5 {
    font-size: 1.5rem;
  }
  .blockquote h6 {
    font-size: 1.5rem;
  }
  .blockquote .text {
    padding-left: 4.358974359%;
  }
  .blockquote p {
    line-height: 2 !important;
  }
  .blockquote p + p {
    margin-top: 20px !important;
  }
  .blockquote cite {
    text-align: left;
  }
  /* 記事一覧 */
  .articleList__item {
    border-radius: 8px;
  }
  .articleList__item .itemWrap {
    display: block;
  }
  .articleList__item .itemWrap .thumb {
    width: 100%;
  }
  .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList__item .itemWrap .textWrap {
    width: 100%;
    padding: 16px 5.1282051282%;
  }
  .articleList__item .itemWrap .textWrap__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 0;
    padding-bottom: 14px;
  }
  .articleList__item .itemWrap .moreBtn {
    width: 100%;
    position: static;
  }
  .articleList__item .itemWrap .moreBtn .more {
    font-size: 1.4rem;
  }
  .articleList__item .itemWrap .moreBtn .more::after {
    width: 16px;
    height: 16px;
    margin: 0 0 2px 4px;
  }
  .articleList__item + .articleList__item {
    margin-top: 20px;
  }
  .articleList.col--2 {
    display: flex;
  }
  .articleList.col--2 .articleList__item {
    padding-bottom: 16px;
    margin-bottom: 30px;
    width: 46.9230769231%;
  }
  .articleList.col--2 .articleList__item .itemWrap .thumb {
    width: 100%;
    margin: 0 0 15px;
  }
  .articleList.col--2 .articleList__item .itemWrap .thumb + .textWrap {
    width: 100%;
  }
  .articleList.col--2 .articleList__item .itemWrap .textWrap__ttl {
    font-size: 1.8rem;
    line-height: 1.5555;
    margin-bottom: 10px;
  }
  .articleList.col--2 .articleList__item .itemWrap .textWrap__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 0;
  }
  .articleList.col--2 .articleList__item:nth-child(2n) {
    margin-left: 6.1538461538%;
  }
  .articleListWrap {
    padding: 30px 4.358974359% 0;
  }
  .articleListWrap__in {
    padding: 30px 4.358974359% 40px;
    border-radius: 6px;
  }
  .articleListWrap .articleList {
    margin: 30px 0;
  }
}
/*=================================
  page--area
=================================*/
.areaDetail {
  padding: 30px 1% 0;
  /*---------------------
    記事タイトル
  ---------------------*/
  /*---------------------
    日付
  ---------------------*/
  /*---------------------
    メインビジュアル
  ---------------------*/
  /*---------------------
    目次
  ---------------------*/
  /*---------------------
    ポイント
  ---------------------*/
  /*---------------------
    関連記事
  ---------------------*/
}
.areaDetail__in {
  max-width: 1140px;
  margin: 0 auto;
  padding: 38px 70px 48px;
  background: #fff;
  border: 1px solid #D2D2D2;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  /* 業者 */
  /* ボタン */
  /* 口コミ */
}
.areaDetail__in h1, .areaDetail__in h2, .areaDetail__in h3, .areaDetail__in h4, .areaDetail__in h5, .areaDetail__in h6 {
  clear: both;
}
.areaDetail__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.6875;
}
.areaDetail__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.areaDetail__in h1:not([class]) {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  background: #EC5934;
  margin-top: 70px;
  margin-bottom: 40px;
  margin-left: -70px;
  padding: 32px 70px;
  width: calc(100% + 140px);
}
.areaDetail__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.23529;
  margin-top: 70px;
  margin-bottom: 16px;
  padding: 7px 0 7px 14px;
  border-left: 4px solid #EC5934;
  position: relative;
}
.areaDetail__in h3:not([class]) {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 15px;
  margin-top: 70px;
  margin-bottom: 30px;
  border-bottom: 2px solid #D2D2D2;
  position: relative;
}
.areaDetail__in h3:not([class])::after {
  content: "";
  width: 25%;
  height: 2px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.areaDetail__in h4:not([class]) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #EC5934;
  line-height: 1.66666;
  padding: 15px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.areaDetail__in h5:not([class]) {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.44444;
  background: #F6F6F6;
  padding: 12px 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-left: 4px solid #EC5934;
}
.areaDetail__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.areaDetail__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  top: 8px;
}
.areaDetail__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.areaDetail__in blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.areaDetail__in blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.areaDetail__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.areaDetail__in blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.areaDetail__in blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.areaDetail__in blockquote p + p {
  margin-top: 50px !important;
}
.areaDetail__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}
.areaDetail__in .btnWrap {
  clear: both;
}
.areaDetail__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.areaDetail__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.areaDetail__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.areaDetail__in > a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.areaDetail__in ul:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  clear: both;
}
.areaDetail__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 38px;
  position: relative;
}
.areaDetail__in ul:not([class]) li::before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.areaDetail__in ul:not([class]) li + li {
  margin-top: 20px;
}
.areaDetail__in ul:not([class]) h6::before {
  content: none;
}
.areaDetail__in ol:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  background: #FFEAD7;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.areaDetail__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 34px;
  position: relative;
}
.areaDetail__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 26px;
  line-height: 26px;
  text-align: center;
  background: #EC5934;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.areaDetail__in ol:not([class]) li + li {
  margin-top: 20px;
}
.areaDetail__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .areaDetail__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.6875;
  }
  .areaDetail__in p:not([class]) + p:not([class]) {
    margin-top: 20px;
  }
  .areaDetail__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.3;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: calc(-4.358974359% - 2px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 4px);
  }
  .areaDetail__in h2:not([class]) {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 4px 0 4px 12px;
  }
  .areaDetail__in h3:not([class]) {
    font-size: 1.8rem;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-top: 50px;
  }
  .areaDetail__in h4:not([class]) {
    font-size: 1.7rem;
    line-height: 1.3;
    padding: 10px 4.358974359%;
  }
  .areaDetail__in h5:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 12px 4.358974359%;
    margin-top: 50px;
  }
  .areaDetail__in h6:not([class]) {
    font-size: 1.7rem;
  }
  .areaDetail__in blockquote {
    padding: 30px 4.358974359%;
  }
  .areaDetail__in blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .areaDetail__in blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .areaDetail__in blockquote h1 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote h2 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote h3 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote h4 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote h5 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote h6 {
    font-size: 1.5rem;
  }
  .areaDetail__in blockquote .text {
    padding-left: 4.358974359%;
  }
  .areaDetail__in blockquote p {
    line-height: 2 !important;
  }
  .areaDetail__in blockquote p + p {
    margin-top: 20px !important;
  }
  .areaDetail__in blockquote cite {
    text-align: left;
  }
  .areaDetail__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .areaDetail__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .areaDetail__in ul:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .areaDetail__in ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 28px;
  }
  .areaDetail__in ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 3px;
  }
  .areaDetail__in ul:not([class]) li + li {
    margin-top: 15px;
  }
  .areaDetail__in ol:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .areaDetail__in ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .areaDetail__in ol:not([class]) li::before {
    width: 22px;
    line-height: 22px;
    top: 2px;
  }
  .areaDetail__in ol:not([class]) li + li {
    margin-top: 15px;
  }
}
.areaDetail__in iframe {
  width: 100%;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .areaDetail__in {
    padding: 2.7818448023vw 5.1244509517vw 3.513909224vw;
  }
}
.areaDetail__in .link {
  text-align: right;
}
.areaDetail__in .link a {
  font-size: 1.5rem;
  position: relative;
}
.areaDetail__in .link a::after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: 0 0 0 6px;
}
.areaDetail__in .introItem .contWrap {
  position: relative;
}
.areaDetail__in .introItem .contWrap .thumbWrap {
  margin: 0 0 27px;
  text-align: center;
}
.areaDetail__in .introItem .contWrap .thumbWrap img {
  margin: 0;
}
.areaDetail__in .introItem .contWrap .cont__summary {
  font-size: 2rem;
  line-height: 1.35;
  margin-bottom: 10px;
}
.areaDetail__in .introItem .contWrap .cont__ttl {
  font-size: 3.2rem;
  line-height: 1.21875;
  color: #EC5934;
}
.areaDetail__in .introItem .contWrap .cont__list {
  background: #FFEAD7;
  padding: 24px 28px;
  margin-top: 30px;
}
.areaDetail__in .introItem .contWrap .cont__listItem {
  font-size: 2rem;
  line-height: 1.7;
  color: #EC5934;
  display: inline-block;
  margin-right: 27px;
  padding-left: 30px;
  position: relative;
}
.areaDetail__in .introItem .contWrap .cont__listItem::before {
  content: "";
  width: 21px;
  height: 16px;
  display: inline-block;
  background: url(../img/icon/icon_check_orange.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.areaDetail__in .introItem .contWrap .cont__text {
  font-size: 2rem;
  line-height: 1.85;
  margin-top: 40px;
}
.areaDetail__in .btnWrap.orange {
  width: calc(100% + 140px);
  margin-left: -70px;
}
.areaDetail__in .btnWrap.orange.end {
  border-radius: 0 0 8px 8px;
  margin-bottom: -48px;
}
.areaDetail__in .evaluation {
  background: #F6F6F6;
  padding: 40px 46px 30px 53px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 30px;
  position: relative;
}
.areaDetail__in .evaluation::before {
  content: "";
  width: 38px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_comments.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: -9px;
  left: 15px;
}
.areaDetail__in .evaluation::after {
  content: "";
  display: inline-block;
  border-style: solid;
  border-width: 23px 16px 0 16px;
  border-color: #F6F6F6 transparent transparent transparent;
  position: absolute;
  left: 50%;
  bottom: -23px;
}
.areaDetail .articleTtl {
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 1.38888;
}
.areaDetail .articleDate {
  font-family: "Open Sans", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #EC5934;
  line-height: 1.46666;
  margin-bottom: 10px;
}
.areaDetail .articleMv {
  margin: 18px auto 16px;
  text-align: center;
}
.areaDetail .tocBox {
  border-radius: 10px;
  border: 1px solid #D2D2D2;
  margin: 32px 0 110px;
  padding: 27px 2.9282576867vw 30px;
}
.areaDetail .tocBox__ttl {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.35;
  margin-bottom: 10px;
  text-align: center;
}
.areaDetail .tocBox .tocList {
  counter-reset: tocNum;
}
.areaDetail .tocBox .tocList__item {
  position: relative;
}
.areaDetail .tocBox .tocList__item::before {
  counter-increment: tocNum;
  content: counter(tocNum) ".";
  font-size: 1.6rem;
  font-weight: 400;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}
.areaDetail .tocBox .tocList__item a {
  font-size: 1.6rem;
  display: inline-block;
  padding: 0 0 0 24px;
}
.areaDetail .tocBox .tocList__item + .tocList__item {
  margin-top: 10px;
}
.areaDetail .tocBox .tocList__item .tocList {
  counter-reset: tocChildNum;
  padding-left: 26px;
}
.areaDetail .tocBox .tocList__item .tocList__item::before {
  counter-increment: tocChildNum;
  content: counter(tocNum) " - " counter(tocChildNum) ".";
}
.areaDetail .tocBox .tocList__item .tocList__item a {
  padding-left: 46px;
}
.areaDetail .tocBox .tocList__item .tocList__item + .tocList__item {
  margin-top: 10px;
}
.areaDetail .point {
  padding: 37px 2.9282576867vw;
  background: #FFEAD7;
  border: 1px solid #EC5934;
  margin: 114px 0 30px;
  position: relative;
}
.areaDetail .point__label {
  font-size: 1.7rem;
  font-weight: 400;
  line-height: 1;
  text-align: center;
  color: #fff;
  background: #EC5934;
  padding: 14px 54px;
  position: absolute;
  top: -44px;
  left: -1px;
}
.areaDetail .point__label::before {
  content: "";
  width: 15px;
  height: 17px;
  display: inline-block;
  background: url(../img/icon/icon_hand.png) no-repeat center center;
  background-size: contain;
  margin: 0 4px -2px 0;
}
.areaDetail .point__cont p {
  padding-left: 38px;
  position: relative;
}
.areaDetail .point__cont p::before {
  content: "";
  width: 25px;
  height: 25px;
  display: inline-block;
  background: url(../img/icon/icon_check_circle.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 4px;
  left: 0;
}
.areaDetail .articleList {
  margin: 36px 0 67px;
}
.areaDetail .articleList__item .itemWrap .thumb {
  position: relative;
}
.areaDetail .articleList__item .itemWrap .thumb::after {
  content: "";
  width: 100%;
  height: 100%;
  display: block;
  background: url(../img/related_cover.png) no-repeat center center;
  background-size: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.areaDetail .articleList__item + .articleList__item {
  margin-top: 15px;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .areaDetail {
    /*---------------------
      記事タイトル
    ---------------------*/
    /*---------------------
      日付
    ---------------------*/
    /*---------------------
      メインビジュアル
    ---------------------*/
    /*---------------------
      目次
    ---------------------*/
  }
  .areaDetail .articleTtl {
    font-size: 2.2rem;
    line-height: 1.4;
  }
  .areaDetail .articleDate {
    font-size: 1.3rem;
    margin-bottom: 15px;
  }
  .areaDetail .articleMv {
    margin: 20px auto 30px;
  }
  .areaDetail .tocBox {
    border-radius: 6px;
    margin: 50px 0 30px;
    padding: 30px 4.358974359%;
  }
  .areaDetail .tocBox__ttl {
    font-size: 1.7rem;
    line-height: 1.3;
    margin-bottom: 15px;
  }
  .areaDetail .tocBox .tocList__item::before {
    font-size: 1.4rem;
  }
  .areaDetail .tocBox .tocList__item a {
    font-size: 1.5rem;
    padding: 0 0 0 20px;
  }
  .areaDetail .tocBox .tocList__item .tocList {
    padding-left: 20px;
    margin-top: 10px;
  }
  .areaDetail .tocBox .tocList__item .tocList__item a {
    padding-left: 40px;
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    page--area
  =================================*/
  .areaDetail {
    padding: 30px 4.358974359%;
  }
  .areaDetail__in {
    padding: 30px 4.358974359% 40px;
    border-radius: 6px;
    /* 業者 */
    /* ボタン */
    /* 口コミ */
  }
  .areaDetail__in .link {
    text-align: left;
  }
  .areaDetail__in .introItem .contWrap .thumbWrap {
    margin: 0 0 20px;
  }
  .areaDetail__in .introItem .contWrap .cont__summary {
    font-size: 1.8rem;
    margin-bottom: 8px;
  }
  .areaDetail__in .introItem .contWrap .cont__ttl {
    font-size: 2.4rem;
    line-height: 1.625;
  }
  .areaDetail__in .introItem .contWrap .cont__list {
    padding: 18px 5.641025641% 28px;
    margin-top: 16px;
  }
  .areaDetail__in .introItem .contWrap .cont__listItem {
    font-size: 1.6rem;
    margin: 10px 0 0;
    display: block;
    padding-left: 28px;
  }
  .areaDetail__in .introItem .contWrap .cont__listItem::before {
    width: 18px;
    height: 14px;
    top: 6px;
    transform: translateY(0);
  }
  .areaDetail__in .introItem .contWrap .cont__text {
    font-size: 1.6rem;
    line-height: 1.75;
    margin-top: 20px;
  }
  .areaDetail__in .btnWrap.orange {
    width: calc(108.7179487179% + 4px);
    margin-left: calc(-4.358974359% - 2px);
  }
  .areaDetail__in .btnWrap.orange.end {
    border-radius: 0 0 6px 6px;
    margin-bottom: -40px;
  }
  .areaDetail__in .evaluation {
    padding: 30px 4.358974359%;
    border-radius: 6px;
  }
  .areaDetail__in .evaluation::before {
    width: 34px;
    height: 26px;
    top: -13px;
    left: 4.358974359%;
  }
  .areaDetail__in .evaluation::after {
    border-width: 18px 12px 0 12px;
    bottom: -18px;
  }
}
/*=================================
  お問い合わせ
=================================*/
.contactWrap {
  padding: 60px 1%;
}
.contactWrap__in {
  max-width: 1140px;
  margin: 0 auto;
  padding: 70px 60px 70px;
  background: #fff;
}
.contactWrap__in h1, .contactWrap__in h2, .contactWrap__in h3, .contactWrap__in h4, .contactWrap__in h5, .contactWrap__in h6 {
  clear: both;
}
.contactWrap__in p:not([class]) {
  font-size: 1.6rem;
  line-height: 1.6875;
}
.contactWrap__in p:not([class]) + p:not([class]) {
  margin-top: 20px;
}
.contactWrap__in h1:not([class]) {
  font-size: 3rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.2;
  background: #EC5934;
  margin-top: 70px;
  margin-bottom: 40px;
  margin-left: -70px;
  padding: 32px 70px;
  width: calc(100% + 140px);
}
.contactWrap__in h2:not([class]) {
  font-size: 2.6rem;
  font-weight: 600;
  line-height: 1.23529;
  margin-top: 70px;
  margin-bottom: 16px;
  padding: 7px 0 7px 14px;
  border-left: 4px solid #EC5934;
  position: relative;
}
.contactWrap__in h3:not([class]) {
  font-size: 2rem;
  font-weight: 600;
  line-height: 1.5;
  padding-bottom: 15px;
  margin-top: 70px;
  margin-bottom: 30px;
  border-bottom: 2px solid #D2D2D2;
  position: relative;
}
.contactWrap__in h3:not([class])::after {
  content: "";
  width: 25%;
  height: 2px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  bottom: -2px;
}
.contactWrap__in h4:not([class]) {
  font-size: 1.8rem;
  font-weight: 600;
  color: #EC5934;
  line-height: 1.66666;
  padding: 15px 30px;
  margin-top: 50px;
  margin-bottom: 30px;
  border-top: 1px solid #D2D2D2;
  border-bottom: 1px solid #D2D2D2;
}
.contactWrap__in h5:not([class]) {
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 1.44444;
  background: #F6F6F6;
  padding: 12px 20px;
  margin-top: 60px;
  margin-bottom: 30px;
  border-left: 4px solid #EC5934;
}
.contactWrap__in h6:not([class]) {
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 1.44444;
  margin-top: 30px;
  margin-bottom: 20px;
  padding-left: 20px;
  position: relative;
}
.contactWrap__in h6:not([class])::before {
  content: "";
  width: 10px;
  height: 10px;
  display: inline-block;
  background: #EC5934;
  position: absolute;
  left: 0;
  top: 8px;
}
.contactWrap__in blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
}
.contactWrap__in blockquote::before {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: -12px;
  left: 22px;
}
.contactWrap__in blockquote::after {
  content: "";
  width: 28px;
  height: 24px;
  background: url(../img/icon/icon_quote.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  bottom: -12px;
  right: 22px;
}
.contactWrap__in blockquote h1 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote h2 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote h3 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote h4 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote h5 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote h6 {
  margin: 0 0 20px !important;
  font-size: 1.6rem;
  line-height: 1.75;
  letter-spacing: 0.06em;
  position: relative;
}
.contactWrap__in blockquote .text {
  border-left: 2px solid #D2D2D2;
  padding-left: 22px;
}
.contactWrap__in blockquote p {
  font-style: normal;
  line-height: 2.1875 !important;
}
.contactWrap__in blockquote p + p {
  margin-top: 50px !important;
}
.contactWrap__in blockquote cite {
  display: block;
  text-align: right;
  line-height: 1;
  color: #888888;
  font-size: 0.9em;
  margin: 15px 0 0;
}
.contactWrap__in .btnWrap {
  clear: both;
}
.contactWrap__in img:not([class]) {
  display: block;
  margin: 15px auto 20px;
}
.contactWrap__in img.alignleft {
  float: left;
  margin: 0 30px 30px 0;
}
.contactWrap__in img.alignright {
  float: right;
  margin: 0 0 30px 30px;
}
.contactWrap__in > a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.contactWrap__in ul:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  border: 1px solid #D2D2D2;
  border-radius: 10px;
  clear: both;
}
.contactWrap__in ul:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 38px;
  position: relative;
}
.contactWrap__in ul:not([class]) li::before {
  content: "";
  width: 25px;
  height: 25px;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  display: inline-block;
  position: absolute;
  top: 2px;
  left: 0;
}
.contactWrap__in ul:not([class]) li + li {
  margin-top: 20px;
}
.contactWrap__in ul:not([class]) h6::before {
  content: none;
}
.contactWrap__in ol:not([class]) {
  margin: 40px 0;
  padding: 30px 2.9282576867vw;
  background: #FFEAD7;
  border-radius: 10px;
  counter-reset: listNum;
  clear: both;
}
.contactWrap__in ol:not([class]) li {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.625;
  padding-left: 34px;
  position: relative;
}
.contactWrap__in ol:not([class]) li::before {
  counter-increment: listNum;
  content: counter(listNum);
  font-family: "Roboto", sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  width: 26px;
  line-height: 26px;
  text-align: center;
  background: #EC5934;
  display: inline-block;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
}
.contactWrap__in ol:not([class]) li + li {
  margin-top: 20px;
}
.contactWrap__in ol:not([class]) h6::before {
  content: none;
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .contactWrap__in p:not([class]) {
    font-size: 1.5rem;
    line-height: 1.6875;
  }
  .contactWrap__in p:not([class]) + p:not([class]) {
    margin-top: 20px;
  }
  .contactWrap__in h1:not([class]) {
    font-size: 2.2rem;
    line-height: 1.3;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: calc(-4.358974359% - 2px);
    padding: 15px 4.358974359%;
    width: calc(108.7179487179% + 4px);
  }
  .contactWrap__in h2:not([class]) {
    font-size: 2rem;
    margin-top: 50px;
    margin-bottom: 20px;
    padding: 4px 0 4px 12px;
  }
  .contactWrap__in h3:not([class]) {
    font-size: 1.8rem;
    line-height: 1.3;
    padding-bottom: 12px;
    margin-top: 50px;
  }
  .contactWrap__in h4:not([class]) {
    font-size: 1.7rem;
    line-height: 1.3;
    padding: 10px 4.358974359%;
  }
  .contactWrap__in h5:not([class]) {
    font-size: 1.6rem;
    line-height: 1.3;
    padding: 12px 4.358974359%;
    margin-top: 50px;
  }
  .contactWrap__in h6:not([class]) {
    font-size: 1.7rem;
  }
  .contactWrap__in blockquote {
    padding: 30px 4.358974359%;
  }
  .contactWrap__in blockquote::before {
    width: 24px;
    height: 20px;
    top: -10px;
    left: 4.358974359%;
  }
  .contactWrap__in blockquote::after {
    width: 24px;
    height: 20px;
    bottom: -10px;
    right: 4.358974359%;
  }
  .contactWrap__in blockquote h1 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote h2 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote h3 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote h4 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote h5 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote h6 {
    font-size: 1.5rem;
  }
  .contactWrap__in blockquote .text {
    padding-left: 4.358974359%;
  }
  .contactWrap__in blockquote p {
    line-height: 2 !important;
  }
  .contactWrap__in blockquote p + p {
    margin-top: 20px !important;
  }
  .contactWrap__in blockquote cite {
    text-align: left;
  }
  .contactWrap__in img.alignleft {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .contactWrap__in img.alignright {
    float: none;
    display: block;
    margin: 15px auto 20px;
  }
  .contactWrap__in ul:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .contactWrap__in ul:not([class]) li {
    font-size: 1.5rem;
    padding-left: 28px;
  }
  .contactWrap__in ul:not([class]) li::before {
    width: 20px;
    height: 20px;
    top: 3px;
  }
  .contactWrap__in ul:not([class]) li + li {
    margin-top: 15px;
  }
  .contactWrap__in ol:not([class]) {
    margin: 30px 0;
    padding: 20px 4.358974359%;
    border-radius: 6px;
  }
  .contactWrap__in ol:not([class]) li {
    font-size: 1.5rem;
    padding-left: 30px;
  }
  .contactWrap__in ol:not([class]) li::before {
    width: 22px;
    line-height: 22px;
    top: 2px;
  }
  .contactWrap__in ol:not([class]) li + li {
    margin-top: 15px;
  }
}
.contactWrap__in iframe {
  width: 100%;
}
.contactWrap .ttl--contact {
  font-size: 3.4rem;
  font-weight: 500;
  color: #fff;
  line-height: 1.05;
  background: #EC5934;
  margin-bottom: 60px;
  margin-left: -60px;
  margin-top: 60px;
  padding: 47px 48px;
  width: calc(100% + 120px);
}
.contactWrap .ttl--contact:first-child {
  margin-top: -70px;
}
.contactWrap .ttl--form {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 2;
  margin-top: 64px;
  margin-bottom: 60px;
  padding: 10px 0;
  border-bottom: 2px solid #EC5934;
}

.contactForm {
  background: #F6F6F6;
  padding: 45px 90px 50px;
}
.contactForm .formWrap .step {
  display: flex;
  justify-content: center;
  counter-reset: stepNum;
  margin-bottom: 56px;
}
.contactForm .formWrap .step__item {
  padding: 16px 32px 16px 35px;
  position: relative;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .contactForm .formWrap .step__item {
    padding: 1.1713030747vw 2.3426061493vw 1.1713030747vw 2.5622254758vw;
  }
}
.contactForm .formWrap .step__item::after {
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 34px 0 34px 24px;
  border-color: transparent transparent transparent transparent;
  position: absolute;
  top: -1px;
  right: -24px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .contactForm .formWrap .step__item::after {
    border-width: 2.4890190337vw 0 2.4890190337vw 1.756954612vw;
    top: -0.0732064422vw;
    right: -1.756954612vw;
  }
}
.contactForm .formWrap .step__item span {
  font-size: 1.8rem;
  font-weight: 500;
  text-align: center;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .contactForm .formWrap .step__item span {
    font-size: 1.317715959vw;
  }
}
.contactForm .formWrap .step__item span::before {
  counter-increment: stepNum;
  content: counter(stepNum);
  font-size: 1.2rem;
  font-weight: 700;
  color: #fff;
  background: #222222;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  margin-right: 9px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .contactForm .formWrap .step__item span::before {
    font-size: 0.878477306vw;
    width: 1.9033674963vw;
    line-height: 1.9033674963vw;
    margin-right: 0.6588579795vw;
  }
}
.contactForm .formWrap .step__item.current {
  background: #FFEAD7;
}
.contactForm .formWrap .step__item.current::after {
  border-color: transparent transparent transparent #FFEAD7;
}
.contactForm .formWrap .step__item.current span {
  color: #EC5934;
}
.contactForm .formWrap .step__item.current span::before {
  background: #EC5934;
}
.contactForm .formWrap .step__item + .step__item {
  margin-left: 24px;
}
.contactForm .formWrap .formCont {
  margin-bottom: 40px;
}
.contactForm .formWrap .formCont__item {
  border-bottom: 1px dashed #888;
  display: flex;
}
.contactForm .formWrap .formCont__item:first-child {
  border-top: 1px dashed #888;
}
.contactForm .formWrap .formCont__ttl {
  font-size: 1.8rem;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 30px 5px;
  width: 280px;
}
.contactForm .formWrap .formCont__ttl .required {
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  background: #EC5934;
  padding: 3px 12px;
}
.contactForm .formWrap .formCont__text {
  width: calc(100% - 280px);
  padding: 30px 20px;
}
.contactForm .formWrap .formCont__text input {
  border: 2px solid #ccc;
  background: #fff;
  padding: 13px 20px;
  width: 100%;
}
.contactForm .formWrap .formCont__text textarea {
  border: 2px solid #ccc;
  background: #fff;
  padding: 13px 20px;
  width: 100%;
}
.contactForm .formWrap .btnWrap {
  margin-top: 40px;
}
.contactForm .formWrap .btn--form {
  display: inline-block;
  text-align: center;
}
@media screen and (min-width: 767px) {
  .contactForm .formWrap .btn--form:hover input {
    border-bottom: 6px solid transparent;
    transform: translateY(6px);
  }
}
.contactForm .formWrap .btn--form input {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  min-width: 350px;
  padding: 31px 10px;
  background: #EC5934;
  background-size: 30px auto;
  display: block;
  border-bottom: 6px solid #aa2403;
  cursor: pointer;
}
.contactForm .formWrap .btn--form.back input {
  background: #575757;
  border-bottom: 6px solid #383838;
}
@media screen and (min-width: 767px) {
  .contactForm .formWrap .btn--form.back:hover input {
    border-bottom: 6px solid transparent;
    transform: translateY(6px);
  }
}
.contactForm .formWrap__text {
  font-size: 1.7rem;
  font-weight: 400;
  text-align: center;
}
.contactForm .formWrap__text a {
  font-weight: 500;
}

@media screen and (max-width: 920px) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactWrap {
    padding: 50px 4.358974359%;
  }
  .contactWrap__in {
    padding: 50px 4.358974359% 50px;
  }
  .contactWrap .ttl--contact {
    font-size: 2.2rem;
    margin-bottom: 30px;
    margin-left: -4.358974359%;
    margin-top: 50px;
    padding: 15px 4.358974359% 15px calc(4.358974359% + 32px);
    width: 108.7179487179%;
    position: relative;
  }
  .contactWrap .ttl--contact:first-child {
    margin-top: -50px;
  }
  .contactWrap .ttl--form {
    font-size: 2rem;
    line-height: 1.23529;
    margin-top: 50px;
    margin-bottom: 50px;
    padding: 8px 0;
  }
  .contactForm {
    padding: 40px 4.358974359%;
  }
  .contactForm .formWrap .step {
    margin-bottom: 30px;
  }
  .contactForm .formWrap .step__item {
    padding: 2.5641025641vw;
  }
  .contactForm .formWrap .step__item::after {
    content: none;
  }
  .contactForm .formWrap .step__item span {
    font-size: 3.0769230769vw;
  }
  .contactForm .formWrap .step__item span::before {
    font-size: 1.2rem;
    width: 22px;
    line-height: 22px;
    margin: 0 auto 6px;
    display: block;
  }
  .contactForm .formWrap .step__item + .step__item {
    margin-left: 2.5641025641vw;
  }
  .contactForm .formWrap .formCont {
    margin-bottom: 40px;
  }
  .contactForm .formWrap .formCont__item {
    display: block;
  }
  .contactForm .formWrap .formCont__ttl {
    font-size: 1.6rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 0;
    width: 100%;
  }
  .contactForm .formWrap .formCont__ttl .required {
    font-size: 1.4rem;
    padding: 3px 10px;
    margin-left: 10px;
  }
  .contactForm .formWrap .formCont__text {
    width: 100%;
    padding: 10px 0 20px;
  }
  .contactForm .formWrap .formCont__text input {
    padding: 13px 4.358974359%;
    width: 100%;
  }
  .contactForm .formWrap .formCont__text textarea {
    padding: 13px 4.358974359%;
    width: 100%;
  }
  .contactForm .formWrap .btnWrap {
    display: block;
  }
  .contactForm .formWrap .btn--form {
    display: block;
  }
  .contactForm .formWrap .btn--form input {
    font-size: 1.6rem;
    min-width: initial;
    width: 100%;
    padding: 20px 4.358974359%;
    background: url(../img/icon/icon_arrow_w.png) no-repeat right 4.358974359% center #EC5934;
    background-size: 10px auto;
  }
  .contactForm .formWrap__text {
    font-size: 1.6rem;
  }
}
/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
}
.header__top {
  max-width: 1140px;
  margin: 0 auto;
  position: relative;
}
.header__top .ttl--site {
  width: 29%;
  display: block;
  position: absolute;
  top: 20px;
  left: 0;
}
.header__top .ttl--site .logo {
  display: block;
}
@media screen and (min-width: 767px) {
  .header__top .ttl--site:hover .logo {
    opacity: 0.8;
  }
}
.header .keySearch {
  max-width: calc(1140px + 2%);
  margin: 0 auto;
  padding: 24px 1%;
  text-align: right;
}
.header .keySearch form {
  display: inline-block;
}
.header .keySearch .searchBox {
  width: 264px;
  border: none;
  position: relative;
}
.header .keySearch .searchBox .input {
  background: url(../img/icon/icon_search.png) no-repeat left 18px center #F3F3F3;
  background-size: auto 14px;
  border-radius: 50px;
  padding: 6px 50px;
  width: 100%;
}
.header .keySearch .searchBox .input::placeholder {
  font-size: 1.6rem;
  color: #222;
}
.header .keySearch .searchBox .submit {
  width: 40px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  cursor: pointer;
}
.header .gNavList {
  background: #FFEAD7;
  padding: 15px 0;
}
.header .gNavList__inner {
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.header .gNavList__item {
  display: inline-block;
  margin: 0 10px;
}
@media screen and (min-width: 767px) {
  .header .gNavList__item:hover a {
    font-weight: 500;
    color: #fff;
    background: #EC5934;
  }
}
.header .gNavList__item a {
  font-size: 1.7rem;
  font-weight: 400;
  color: #222222;
  padding: 19px 29px;
  text-decoration: none;
  display: block;
  background: transparent;
  border-radius: 10px;
}

@media screen and (min-width: 920px) {
  .gNavList.fixedNav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    box-shadow: 10px 3px 36px rgba(0, 0, 0, 0.1);
    z-index: 999;
    animation: headerFade 1s ease-in-out;
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 9999;
    /*バーガーボタン設定*/
  }
  .header__top {
    padding: 13px 17px;
    background: #fff;
  }
  .header__top .ttl--site {
    width: 80%;
    max-width: 222px;
    position: static;
  }
  .header .keySearch {
    max-width: initial;
    padding: 20px 8.7179487179%;
    text-align: center;
    background: #fff;
    border-top: 1px solid #D2D2D2;
  }
  .header .keySearch form {
    display: block;
  }
  .header .keySearch .searchBox {
    width: 100%;
  }
  .header .keySearch .searchBox .input {
    background: url(../img/icon/icon_search.png) no-repeat left 22px center #F3F3F3;
    background-size: auto 17px;
    padding: 15px 50px;
    width: 100%;
  }
  .header .keySearch .searchBox .input::placeholder {
    font-size: 1.6rem;
    color: #222;
  }
  .header .keySearch .searchBox .submit {
    width: 40px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
  }
  .header .gNav {
    overflow-y: scroll;
    max-height: 90vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out, transform 0.3s ease-out, visibility 0.2s ease-out;
  }
  .header .gNavList {
    padding: 7.6923076923% 4.358974359% 2.5641025641%;
  }
  .header .gNavList__item {
    display: block;
    width: 47.4358974359%;
    margin: 0 0 5.1282051282%;
  }
  .header .gNavList__item a {
    font-weight: 600;
    color: #fff;
    padding: 22px;
    text-decoration: none;
    display: block;
    background: #EC5934;
    border-radius: 6px;
    text-align: center;
  }
  .header .gNavList__item:nth-child(2n) {
    margin-left: 5.1282051282%;
  }
  .header .gNav.show {
    transform: scale(1);
    visibility: visible;
    opacity: 1;
  }
  .header .gNav.hide {
    transform: scale(0.7);
    opacity: 0;
    visibility: hidden;
  }
  .header .gNav .logo--sub {
    width: calc(100% - 60px);
    max-width: 222px;
    padding: 12px 17px;
    box-sizing: content-box;
  }
  .header .headerNav__cover {
    height: 0;
    width: 100vw;
    background: #fff;
    background-size: cover;
    position: fixed;
    top: 0;
    left: 0;
    display: none;
    z-index: 9998;
  }
  .header .headerNav__cover.show {
    display: block;
    animation: show 0.2s linear 0s;
  }
  .header .headerNav__cover.hide {
    display: none;
    animation: hide 0.2s linear 0s;
  }
  .header .burger {
    width: 60px;
    height: 60px;
    background: #fff;
    margin: auto;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    z-index: 9999;
    transition-property: left;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
    /*クリック後、バツボタンになる*/
  }
  .header .burger span {
    width: 27px;
    height: 3px;
    display: block;
    background: #222;
    position: absolute;
    top: 21px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transition: width 0.2s, right 0.2s, left 0.2s;
  }
  .header .burger span.burger--top {
    transform: translateY(0);
  }
  .header .burger span.burger--middle {
    transform: translateY(7px);
    position: relative;
    background: none;
  }
  .header .burger span.burger--middle::before {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #222;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--middle::after {
    content: "";
    display: block;
    position: absolute;
    width: 27px;
    height: 3px;
    background: #222;
    transition: all 0.2s;
    transform: rotate(0deg);
  }
  .header .burger span.burger--bottom {
    transform: translateY(14px);
  }
  .header .burger.is-open {
    background: #EC5934;
  }
  .header .burger.is-open .burger--top {
    left: 100%;
    width: 0px;
  }
  .header .burger.is-open .burger--middle::before {
    transform: rotate(135deg);
    background: #fff;
  }
  .header .burger.is-open .burger--middle::after {
    transform: rotate(45deg);
    background: #fff;
  }
  .header .burger.is-open .burger--bottom {
    right: 100%;
    width: 0px;
  }
  .header .burger.show {
    position: fixed;
    animation: burger-show 1s ease-in;
  }
}
/*=================================
footer
=================================*/
.footer {
  background: #F3F3F3;
  position: relative;
}
.footerHead {
  max-width: 1140px;
  margin: 0 auto;
  padding: 60px 4.3923% 80px;
  display: flex;
}
.footerHead .cont--left {
  width: 360px;
}
.footerHead .cont--right {
  width: calc(100% - 360px);
}
.footerHead .infoTtl {
  font-size: 1.5rem;
  line-height: 1;
  margin-bottom: 21px;
}
.footerHead .infoText {
  font-size: 1.3rem;
  line-height: 1.69;
}
.footerHead .infoText .text--md {
  font-size: 1.5rem;
}
.footerHead .groupSite {
  border-left: 1px solid #D2D2D2;
  padding: 0 0 0 33px;
  display: flex;
  flex-wrap: wrap;
}
.footerHead .groupSite .infoTtl {
  width: 100%;
}
.footerHead .groupSite__bnr a {
  display: inline-block;
  margin: 0 0 15px 0;
}
@media screen and (min-width: 767px) {
  .footerHead .groupSite__bnr a:hover {
    opacity: 0.8;
  }
}
.footerHead .groupSite__bnr a:nth-child(2n-1) {
  margin-right: 30px;
}
.footer__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.footer__cont {
  background: #EC5934;
  padding: 33px 1%;
}
.footer__cont .footer__inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.footer .logo {
  max-width: 204px;
}
.footer .fNavList__item {
  display: inline-block;
}
.footer .fNavList__item a {
  font-size: 1.3rem;
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 767px) {
  .footer .fNavList__item a:hover {
    text-decoration: underline;
  }
}
.footer .fNavList__item + .fNavList__item {
  margin-left: 3rem;
}
.footer .copy {
  font-family: "Open Sans", sans-serif;
  font-size: 1.3rem;
  color: #fff;
}
.footer .pageTop {
  position: absolute;
  top: -45px;
  right: calc((100% - 1140px) / 2);
  cursor: pointer;
  transition-property: all;
  transition-duration: 0.2s;
  transition-timing-function: ease-out;
}
@media screen and (min-width: 767px) {
  .footer .pageTop:hover {
    transform: translateY(10%);
  }
}
.footer .pageTop.float {
  position: fixed;
  top: initial;
  bottom: 0;
  right: 3%;
  z-index: 5000;
}

.fixBnr {
  background: #FFE043;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  border-radius: 10px 0 0 0;
  position: fixed;
  right: -100%;
  bottom: 80px;
  z-index: 9999;
  transition: right 1.2s ease-out;
}
.fixBnr.show {
  right: 0;
}
.fixBnr .cvBnr {
  padding: 14px 12px 20px 110px;
}
.fixBnr .cvBnr__text {
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2;
  display: block;
}
.fixBnr .cvBnr__link {
  font-size: 2.2rem;
  font-weight: 700;
  color: #1255FF;
  line-height: 1;
  display: block;
  text-align: center;
  text-decoration: none;
}
.fixBnr .cvBnr::after {
  width: 102px;
  height: 112px;
  right: initial;
  left: 0;
}

@media screen and (min-width: 767px) and (max-width: 1300px) {
  /*=================================
  footer
  =================================*/
  .footer .pageTop {
    right: 3%;
  }
}
@media screen and (min-width: 1336px) {
  .fixBnr--article {
    background: #FFE043;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 0;
    right: -80px;
    visibility: hidden;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
  }
  .fixBnr--article.show {
    position: fixed;
    top: 280px;
    right: calc((100% - 1140px) / 2 - 80px);
    z-index: 9999;
    visibility: visible;
    opacity: 1;
  }
  .fixBnr--article .cvBnr {
    padding: 15px 8px 21px 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
  }
  .fixBnr--article .cvBnr__text {
    width: 1.6rem;
    font-size: 1.6rem;
    font-weight: 700;
    line-height: 1;
    margin-left: 5px;
    display: block;
  }
  .fixBnr--article .cvBnr__link {
    width: 2.2rem;
    font-size: 2.2rem;
    font-weight: 700;
    color: #1255FF;
    line-height: 1;
    display: block;
    text-align: center;
    text-decoration: none;
  }
  .fixBnr--article .cvBnr__link::after {
    content: "";
    width: 21px;
    height: 21px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
    background-size: contain;
    margin-top: 10px;
  }
  .fixBnr--article .cvBnr::after {
    content: none;
  }
}
@media screen and (min-width: 767px) and (max-width: 1335px) {
  /*=================================
  footer
  =================================*/
  .fixBnr--article {
    background: #FFE043;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    border-radius: 10px 0 0 0;
    position: fixed;
    right: -100%;
    bottom: 80px;
    z-index: 9999;
    transition: right 1.2s ease-out;
  }
  .fixBnr--article.show {
    right: 0;
  }
  .fixBnr--article .cvBnr {
    padding: 14px 12px 20px 110px;
  }
  .fixBnr--article .cvBnr__text {
    font-size: 1.4rem;
    font-weight: 700;
    line-height: 2;
    display: block;
  }
  .fixBnr--article .cvBnr__link {
    font-size: 2.2rem;
    font-weight: 700;
    color: #1255FF;
    line-height: 1;
    display: block;
    text-align: center;
    text-decoration: none;
  }
  .fixBnr--article .cvBnr::after {
    width: 102px;
    height: 112px;
    right: initial;
    left: 0;
  }
}
@media screen and (max-width: 1080px) {
  /*=================================
  footer
  =================================*/
  .footer__cont {
    text-align: center;
    padding: 28px 1%;
  }
  .footer .logo {
    margin: 0 auto 20px;
  }
  .footer .fNavList {
    width: 100%;
  }
  .footer .copy {
    width: 100%;
    display: block;
    margin: 23px 0 0;
  }
}
@media screen and (max-width: 767px) {
  /*=================================
  footer
  =================================*/
  .footerHead {
    padding: 38px 4.358974359% 30px;
    display: block;
  }
  .footerHead .cont--left {
    width: 100%;
  }
  .footerHead .cont--right {
    width: 100%;
  }
  .footerHead .infoTtl {
    margin-bottom: 17px;
    width: 100%;
    text-align: center;
  }
  .footerHead .address {
    padding: 0 4.358974359%;
  }
  .footerHead .groupSite {
    border-left: none;
    padding: 26px 0 0;
  }
  .footerHead .groupSite__bnr {
    display: flex;
    flex-wrap: wrap;
  }
  .footerHead .groupSite__bnr a {
    margin: 0 0 4.1025641026% 0;
    width: 47.9487179487%;
    display: block;
  }
  .footerHead .groupSite__bnr a:nth-child(2n-1) {
    margin-right: 4.1025641026%;
  }
  .footer .pageTop {
    width: 80px;
    top: initial;
    right: 0;
  }
  .footer .pageTop.float {
    right: 0;
  }
  .fixBnr {
    box-shadow: none;
    border-radius: 0 10px 0 0;
    width: calc(100% - 80px);
    right: initial;
    left: 0;
    bottom: -100%;
    transition: bottom 1s ease-out;
    z-index: 9000;
  }
  .fixBnr::after {
    content: "";
    width: 21px;
    height: 21px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 2.5641025641%;
    transform: translateY(-50%);
  }
  .fixBnr.show {
    bottom: 0;
    right: inherit;
  }
  .fixBnr .cvBnr {
    padding: 14px 7.6923076923% 10px 0;
  }
  .fixBnr .cvBnr__text {
    line-height: 1.2142;
    padding-left: 72px;
    margin: 0 0 4px;
  }
  .fixBnr .cvBnr__link {
    font-size: 1.9rem;
    line-height: 1.25;
    padding-left: 72px;
    text-align: left;
  }
  .fixBnr .cvBnr::after {
    width: 74px;
    height: 81px;
    top: initial;
    bottom: 0;
  }
}
/*=================================
  visual
=================================*/
.mv {
  background: url(../img/top_mv_pc.jpg) no-repeat center center;
  background-size: cover;
  padding: 40px 1% 48px;
}
.mv__inner {
  max-width: 1140px;
  margin: 0 auto;
  padding: 0 52px;
  box-sizing: content-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}
.mv__catch {
  font-size: 4.6rem;
  font-weight: 700;
  color: #fff;
  line-height: 1.36;
  padding-right: 176px;
}
.mv__catch .is-sm {
  font-size: 4rem;
}
.mv__catch .is-lg {
  font-size: 6.6rem;
}
.mv__catch .icon::after {
  content: "";
  width: 38px;
  height: 29px;
  display: inline-block;
  background: url(../img/icon/icon_comment.png) no-repeat center center;
  background-size: contain;
  vertical-align: middle;
  margin: -20px 15px 0;
}
.mv__media {
  width: 200px;
  background: #fff;
  border-radius: 50%;
  padding: 48px 0 72px;
  text-align: center;
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}
.mv__media .ttl {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 30px;
}
.mv__media .img {
  width: 85%;
}

@media screen and (max-width: 767px) {
  /*=================================
    visual
  =================================*/
  .mv {
    background: none;
    padding: 0;
  }
  .mv__inner {
    padding: 0;
    display: block;
  }
  .mv__catch {
    font-size: 2.6rem;
    line-height: 1.6;
    text-align: center;
    padding: 7.6923076923% 4.358974359%;
    background: url(../img/top_mv_sp.jpg) no-repeat center center;
    background-size: cover;
  }
  .mv__catch .is-sm {
    font-size: 2.2rem;
  }
  .mv__catch .is-lg {
    font-size: 3.6rem;
  }
  .mv__catch .icon::after {
    width: 31px;
    height: 24px;
    margin: -15px 3px 0;
  }
  .mv__media {
    width: 100%;
    border-radius: 0;
    padding: 3.0769230769% 0;
    position: relative;
    transform: translateY(0);
  }
  .mv__media .ttl {
    font-size: 1.4rem;
    margin-bottom: 5px;
  }
  .mv__media .img {
    width: 35%;
  }
}
/*=================================
  cvBner
=================================*/
.cvBnrWrap {
  background: #EC5934;
  padding: 0 1%;
}

.cvBnr {
  max-width: 1140px;
  margin: 0 auto;
  padding: 22px 0 29px;
  position: relative;
}
.cvBnr::after {
  content: "";
  width: 274px;
  height: 299px;
  display: inline-block;
  background: url(../img/cvBnr_img.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  right: 10px;
  bottom: 0;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .cvBnr::after {
    width: 19.0336749634vw;
    height: 20.8638360176vw;
    right: 0;
  }
}
.cvBnr__ttl {
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 1.33333;
  letter-spacing: 0.05em;
  color: #fff;
  text-align: center;
  margin: 0 0 26px;
}
.cvBnr .cvBtn {
  background: #FFE043;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
  max-width: 570px;
  padding: 13px 22px 20px 22px;
  margin: 0 auto;
  position: relative;
}
.cvBnr .cvBtn::after {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
}
.cvBnr .cvBtn__text {
  font-size: 2rem;
  font-weight: 700;
  line-height: 2;
  display: block;
}
.cvBnr .cvBtn__link {
  font-size: 4rem;
  font-weight: 700;
  line-height: 1;
  color: #1255FF;
  text-align: center;
  text-decoration: none;
  display: block;
}
@media screen and (min-width: 767px) {
  .cvBnr .cvBtn:hover {
    animation: jump 0.5s ease-in-out;
  }
}
@media screen and (min-width: 0) and (max-width: 767px) {
  .cvBnr .cvBtn {
    animation: jumpSp 5s linear infinite;
  }
}
.cvBnr.is--sm {
  padding: 0;
  margin: 36px 0;
}
.cvBnr.is--sm::after {
  content: none;
}
.cvBnr.is--sm .cvBtn {
  max-width: 640px;
  padding: 16px 70px 18px 160px;
}
.cvBnr.is--sm .cvBtn__link {
  font-size: 2.8rem;
  text-align: left;
}
.cvBnr.is--sm .cvBtn::after {
  right: 39px;
}
.cvBnr.is--sm .cvBtn::before {
  content: "";
  width: 119px;
  height: 129px;
  display: inline-block;
  background: url(../img/cvBnr_img.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  left: 0;
  bottom: 0;
}

@media screen and (max-width: 767px) {
  /*=================================
    cvBner
  =================================*/
  .cvBnrWrap {
    padding: 4.1025641026% 4.358974359%;
    width: 100%;
  }
  .cvBnr {
    padding: 0;
  }
  .cvBnr::after {
    width: 114px;
    height: 124px;
    right: -3.8461538462%;
    bottom: initial;
    top: -45px;
    z-index: 1;
  }
  .cvBnr__ttl {
    font-size: 1.8rem;
    font-weight: 600;
    line-height: 1.5555;
    text-align: left;
    margin: 0 0 9px;
  }
  .cvBnr .cvBtn {
    border-radius: 10px;
    max-width: initial;
    padding: 3.8461538462% 8.9743589744% 4.358974359% 3.3333333333%;
    z-index: 2;
  }
  .cvBnr .cvBtn::after {
    width: 24px;
    height: 24px;
    right: 3.0769230769%;
  }
  .cvBnr .cvBtn__text {
    font-size: 1.8rem;
    line-height: 1.1111;
    margin: 0 0 10px;
  }
  .cvBnr .cvBtn__link {
    font-size: 2.8rem;
    text-align: left;
  }
  .cvBnr.is--sm {
    margin: 30px 0;
  }
  .cvBnr.is--sm::after {
    content: none;
  }
  .cvBnr.is--sm .cvBtn {
    max-width: initial;
    padding: 16px 0;
  }
  .cvBnr.is--sm .cvBtn__text {
    font-size: 1.4rem;
    margin: 0 0 4px;
    padding-left: 72px;
  }
  .cvBnr.is--sm .cvBtn__link {
    font-size: 1.5rem;
    padding-left: 72px;
  }
  .cvBnr.is--sm .cvBtn::after {
    right: 10px;
  }
  .cvBnr.is--sm .cvBtn::before {
    width: 74px;
    height: 81px;
  }
}
/*=================================
  ranking
=================================*/
.ranking {
  background: #F3F3F3;
  padding: 55px 1% 38px;
}
.ranking__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.rankingList {
  counter-reset: rankingNum;
}
.rankingList__item .contWrap {
  background: #fff;
  padding: 60px 60px 28px;
  border: 1px solid #D2D2D2;
  border-bottom: none;
  border-radius: 8px 8px 0 0;
  position: relative;
  display: flex;
  align-items: flex-start;
}
.rankingList__item .contWrap::before {
  font-family: "Open Sans", sans-serif;
  content: counter(rankingNum);
  counter-increment: rankingNum;
  font-size: 3.3rem;
  font-weight: 400;
  color: #fff;
  width: 77px;
  height: 83px;
  display: inline-block;
  text-align: center;
  background: #EC5934;
  position: absolute;
  top: 0;
  right: 60px;
}
.rankingList__item .contWrap .thumbWrap {
  max-width: 328px;
  text-align: center;
}
.rankingList__item .contWrap .thumbWrap + .cont {
  width: calc(100% - 388px);
  margin-left: 60px;
}
.rankingList__item .contWrap .cont__summary {
  font-size: 1.8rem;
  line-height: 1.5;
  padding-right: 80px;
}
.rankingList__item .contWrap .cont__ttl a {
  font-size: 2.8rem;
  line-height: 1.39285;
  padding-right: 80px;
}
.rankingList__item .contWrap .cont__ttl a[target=_blank] {
  position: relative;
}
.rankingList__item .contWrap .cont__ttl a[target=_blank]::after {
  content: "";
  width: 13px;
  height: 12px;
  display: inline-block;
  background: url(../img/icon/icon_link.png) no-repeat center center;
  background-size: contain;
  margin-left: 5px;
}
.rankingList__item .contWrap .cont__list {
  background: #FFEAD7;
  padding: 12px 17px;
  margin-top: 12px;
}
.rankingList__item .contWrap .cont__listItem {
  font-size: 1.4rem;
  line-height: 1.71428;
  color: #EC5934;
  display: inline-block;
  margin-right: 12px;
  padding-left: 19px;
  position: relative;
}
.rankingList__item .contWrap .cont__listItem::before {
  content: "";
  width: 14px;
  height: 11px;
  display: inline-block;
  background: url(../img/icon/icon_check_orange.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}
.rankingList__item .contWrap .cont__text {
  font-size: 1.6rem;
  line-height: 1.6875;
  margin-top: 16px;
}
.rankingList__item .contWrap .cont__more {
  font-size: 1.6rem;
  line-height: 2.4375;
  color: #1255FF;
  text-decoration: underline;
  text-align: right;
}
.rankingList__item .contWrap .cont__more::after {
  content: "";
  width: 20px;
  height: 20px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  margin-left: 6px;
  vertical-align: middle;
}
@media screen and (min-width: 767px) {
  .rankingList__item .contWrap:hover .cont__more {
    text-decoration: none;
  }
}
.rankingList__item .linkWrap {
  background: #EC5934;
  border-radius: 0 0 8px 8px;
  display: flex;
  justify-content: space-between;
  padding: 30px 56px 26px;
}
.rankingList__item .linkWrap .cv {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1.3;
  background: #FFE043;
  padding: 25px 63px 25px 60px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
  position: relative;
  width: calc(55% - 17px);
}
.rankingList__item .linkWrap .cv::after {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  right: 33px;
  transform: translateY(-50%);
}
.rankingList__item .linkWrap .check {
  font-size: 2.2rem;
  font-weight: 700;
  line-height: 1.772727;
  color: #222222;
  background: #fff;
  padding: 25px 100px;
  text-align: center;
  border-radius: 20px;
  text-decoration: none;
  box-shadow: 0 3px 16px rgba(0, 0, 0, 0.16);
  position: relative;
  width: calc(45% - 17px);
}
.rankingList__item .linkWrap .check::before {
  content: "";
  width: 30px;
  height: 30px;
  display: inline-block;
  background: url(../img/icon/icon_check.png) no-repeat center center;
  background-size: contain;
  position: absolute;
  top: 50%;
  left: 28px;
  transform: translateY(-50%);
}
@media screen and (min-width: 767px) {
  .rankingList__item .linkWrap a:hover {
    animation: jump 0.5s ease-in-out;
  }
}
.rankingList__item:nth-child(1) .contWrap::before {
  background: url(../img/rank_01.png) no-repeat center top;
  background-size: contain;
}
.rankingList__item:nth-child(2) .contWrap::before {
  background: url(../img/rank_02.png) no-repeat center top;
  background-size: contain;
}
.rankingList__item:nth-child(3) .contWrap::before {
  background: url(../img/rank_03.png) no-repeat center top;
  background-size: contain;
}
.rankingList__item + .rankingList__item {
  margin-top: 30px;
}

@media screen and (max-width: 767px) {
  /*=================================
    ranking
  =================================*/
  .ranking {
    padding: 37px 4.358974359% 27px;
  }
  .rankingList__item .contWrap {
    padding: 30px 4.358974359% 28px;
    border-radius: 4px 4px 0 0;
    display: block;
  }
  .rankingList__item .contWrap::before {
    font-size: 2.2rem;
    width: 53px;
    height: 57px;
    right: 4.358974359%;
  }
  .rankingList__item .contWrap .thumbWrap {
    max-width: initial;
    text-align: center;
  }
  .rankingList__item .contWrap .thumbWrap + .cont {
    width: 100%;
    margin: 20px 0 0;
  }
  .rankingList__item .contWrap .cont__summary {
    padding-right: 0;
  }
  .rankingList__item .contWrap .cont__ttl a {
    font-size: 2.4rem;
    line-height: 1.625;
    padding-right: 0;
  }
  .rankingList__item .contWrap .cont__ttl a[target=_blank]::after {
    content: "";
    width: 13px;
    height: 12px;
    display: inline-block;
    background: url(../img/icon/icon_link.png) no-repeat center center;
    background-size: contain;
    margin-left: 5px;
  }
  .rankingList__item .contWrap .cont__list {
    padding: 18px 5.641025641% 28px;
    margin-top: 16px;
  }
  .rankingList__item .contWrap .cont__listItem {
    font-size: 1.6rem;
    margin: 10px 0 0;
    display: block;
    padding-left: 28px;
  }
  .rankingList__item .contWrap .cont__listItem::before {
    width: 18px;
    height: 14px;
    top: 6px;
    transform: translateY(0);
  }
  .rankingList__item .contWrap .cont__text {
    line-height: 1.75;
    margin-top: 20px;
  }
  .rankingList__item .linkWrap {
    border-radius: 0 0 6px 6px;
    display: block;
    padding: 30px 4.358974359% 28px;
  }
  .rankingList__item .linkWrap .cv {
    font-size: 2rem;
    line-height: 1.1;
    padding: 26px 8.9743589744% 25px 4.1025641026%;
    border-radius: 8px;
    display: block;
    width: 100%;
  }
  .rankingList__item .linkWrap .cv::after {
    width: 20px;
    height: 20px;
    right: 2.5641025641%;
  }
  .rankingList__item .linkWrap .check {
    font-size: 2rem;
    line-height: 1.35;
    padding: 25px 4.358974359%;
    border-radius: 8px;
    display: block;
    margin-top: 12px;
    width: 100%;
  }
  .rankingList__item .linkWrap .check::before {
    width: 20px;
    height: 20px;
    left: 4.1025641026%;
  }
  .rankingList__item + .rankingList__item {
    margin-top: 20px;
  }
}
/*=================================
  area
=================================*/
.area {
  background: #F3F3F3;
  padding: 38px 1% 70px;
}
.area__inner {
  max-width: 1140px;
  margin: 0 auto;
}
.area__cont {
  display: flex;
  flex-wrap: wrap;
}
.area .areaList {
  width: calc((100% - 30px) / 2);
  border-radius: 10px;
  border: 1px solid #D2D2D2;
  overflow: hidden;
  padding-top: 150px;
  margin-bottom: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .area .areaList {
    width: calc((100% - 2.196193265vw) / 2);
    padding-top: 10.980966325vw;
    margin-bottom: 2.196193265vw;
  }
}
.area .areaList:nth-child(2n) {
  margin-left: 30px;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .area .areaList:nth-child(2n) {
    margin-left: 2.196193265vw;
  }
}
.area .areaList.tohoku {
  background: url(../img/bg_tohoku_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList.kanto {
  background: url(../img/bg_kanto_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList.tokai {
  background: url(../img/bg_tokai_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList.kansai {
  background: url(../img/bg_kansai_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList.chugoku {
  background: url(../img/bg_chugoku_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList.kyushu {
  background: url(../img/bg_kyushu_pc.jpg) no-repeat center top #fff;
  background-size: auto;
}
.area .areaList__item {
  border-top: 1px solid #D2D2D2;
  padding: 27px 30px;
  background: #fff;
}
@media screen and (min-width: 767px) and (max-width: 1366px) {
  .area .areaList__item {
    padding: 1.9765739385vw 2.196193265vw;
  }
}
.area .areaList__name {
  font-size: 1.7rem;
  line-height: 1;
  margin-bottom: 11px;
}
.area .areaList__cont .prefecture__item {
  display: inline-block;
}
.area .areaList__cont .prefecture__item a {
  font-size: 1.5rem;
  line-height: 1.8;
  display: block;
  text-decoration: none;
  padding-right: 2rem;
  margin-right: 2rem;
  position: relative;
}
@media screen and (min-width: 767px) {
  .area .areaList__cont .prefecture__item:hover a {
    text-decoration: underline;
  }
  .area .areaList__cont .prefecture__item:hover a::after {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

@media screen and (max-width: 767px) {
  /*=================================
    area
  =================================*/
  .area {
    padding: 0 4.358974359% 23px;
  }
  .area__cont {
    display: block;
  }
  .area .areaList {
    width: 100%;
    border-radius: 8px;
    padding-top: 0;
    margin-bottom: 17px;
    position: relative;
  }
  .area .areaList:nth-child(2n) {
    margin-left: 0;
  }
  .area .areaList.tohoku {
    background: none;
  }
  .area .areaList.kanto {
    background: none;
  }
  .area .areaList.tokai {
    background: none;
  }
  .area .areaList.kansai {
    background: none;
  }
  .area .areaList.chugoku {
    background: none;
  }
  .area .areaList.kyushu {
    background: none;
  }
  .area .areaList.tohoku .areaList__name {
    background: url(../img/bg_tohoku_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList.kanto .areaList__name {
    background: url(../img/bg_kanto_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList.tokai .areaList__name {
    background: url(../img/bg_tokai_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList.kansai .areaList__name {
    background: url(../img/bg_kansai_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList.chugoku .areaList__name {
    background: url(../img/bg_chugoku_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList.kyushu .areaList__name {
    background: url(../img/bg_kyushu_sp.jpg) no-repeat center top #fff;
    background-size: auto;
  }
  .area .areaList__item {
    border-top: none;
    padding: 0;
  }
  .area .areaList__name {
    font-size: 2rem;
    font-weight: 600;
    color: #fff;
    margin-bottom: 0;
    padding: 30px 17px;
    position: relative;
  }
  .area .areaList__name::after {
    content: "";
    width: 20px;
    height: 12px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 27px;
    transform: translateY(-50%);
  }
  .area .areaList__cont .prefecture {
    display: flex;
    flex-wrap: wrap;
    position: relative;
  }
  .area .areaList__cont .prefecture__item {
    width: 50%;
    display: block;
    border-top: 1px solid #D2D2D2;
  }
  .area .areaList__cont .prefecture__item:nth-child(2n-1) {
    border-right: 1px solid #D2D2D2;
    position: relative;
  }
  .area .areaList__cont .prefecture__item:nth-child(2n-1):last-child::after {
    content: "";
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    display: block;
    position: absolute;
    top: -1%;
    right: -101%;
    border-top: 1px solid #D2D2D2;
  }
  .area .areaList__cont .prefecture__item a {
    font-size: 1.7rem;
    line-height: 1;
    padding: 20px 17px;
    margin-right: 0;
    position: relative;
  }
  .area .areaList__cont .prefecture__item a::after {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    right: 17px;
    top: 50%;
    transform: translateY(-50%);
  }
  .area .js-area-pulldown-item {
    display: none;
  }
  .area .js-area-pulldown.open::after {
    transform: translateY(-50%) rotate(180deg);
  }
}