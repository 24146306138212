@charset "utf-8";

/*=================================
  button
=================================*/
.btnWrap {
  margin: 20px auto 30px;
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }

  > * + * {
    margin-top: 9px;
  }
  &.orange {
    background: $color-theme;
    padding: 30px per(17) 20px;
  }
}
.btn--yellow {
  font-size: 1.8rem;
  font-weight: 700;
  color: $color-blue-dark;
  text-decoration: none;
  background: $color-yellow;
  border-radius: 10px;
  box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
  width: 100%;
  text-align: center;
  padding: 20px per(17);
  margin: 0 auto;
  display: block;
  position: relative;
  &::after {
    content: "";
    width: 20px;
    height: 20px;
    display: inline-block;
    background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
    background-size: contain;
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
  }
  @include mq-desktop {
    &:hover {
      animation: jump .5s ease-in-out;
    }
  }
  @include mq-sp {
    animation: jumpSp 5s linear infinite;
  }
}

@media screen and (min-width: $display-width-s){
  /*=================================
    button
  =================================*/
  .btnWrap {
    display: flex;
    justify-content: center;
    margin: 50px 0;
    > * + * {
      margin: 0 0 0 20px;
    }
    &.orange {
      padding: 32px 1% 24px;
    }
  }
  .btn--yellow {
    font-size: 3rem;
    border-radius: 20px;
    max-width: 535px;
    padding: 23px;
    &::after {
      width: 30px;
      height: 30px;
      right: 33px;
    }
  }
}