@charset "utf-8";
/*=================================
footer
=================================*/
.footer {
  background: #F3F3F3;
  position: relative;
  &Head {
    max-width: $base-width;
    margin: 0 auto;
    padding: 60px 4.3923% 80px;
    display: flex;
    .cont--left {
      width: 360px;
    }
    .cont--right {
      width: calc(100% - 360px);
    }
    .infoTtl {
      font-size: 1.5rem;
      line-height: 1;
      margin-bottom: 21px;
    }
    .infoText {
      font-size: 1.3rem;
      line-height: 1.69;
      .text--md {
        font-size: 1.5rem;
      }
    }
    .groupSite {
      border-left: 1px solid #D2D2D2;
      padding: 0 0 0 33px;
      display: flex;
      flex-wrap: wrap;
      .infoTtl {
        width: 100%;
      }
      &__bnr {
        a {
          display: inline-block;
          margin: 0 0 15px 0;
          @include mq-desktop {
            &:hover {
              opacity: .8;
            }
          }
          &:nth-child(2n - 1) {
            margin-right: 30px;
          }
        }
      }
    }
  }
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__cont {
    background: $color-theme;
    padding: 33px 1%;
    .footer__inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .logo {
    max-width: 204px;
  }
  .fNavList {
    &__item {
      display: inline-block;
      a {
        font-size: 1.3rem;
        color: #fff;
        text-decoration: none;
        @include mq-desktop {
          &:hover {
            text-decoration: underline;
          }
        }
      }
      & + .fNavList__item {
        margin-left: 3rem;
      }
    }
  }
  .copy {
    @include font-os;
    font-size: 1.3rem;
    color: #fff;
  }
  .pageTop {
    position: absolute;
    top: -45px;
    right: calc((100% - #{$base-width}) / 2);
    cursor: pointer;
    @include animation-base;
    @include mq-desktop {
      &:hover {
        transform: translateY(10%);
      }
    }
    &.float {
      position: fixed;
      top: initial;
      bottom: 0;
      right: 3%;
      z-index: 5000;
    }
  }
}

.fixBnr {
  background: $color-yellow;
  box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
  border-radius: 10px 0 0 0;
  position: fixed;
  right: -100%;
  bottom: 80px;
  z-index: 9999;
  transition: right 1.2s ease-out;
  &.show {
    right: 0;
  }
  .cvBnr {
    padding: 14px 12px 20px 110px;
    &__text {
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2;
      display: block;
    }
    &__link {
      font-size: 2.2rem;
      font-weight: 700;
      color: #1255FF;
      line-height: 1;
      display: block;
      text-align: center;
      text-decoration: none;
    }
    &::after {
      width: 102px;
      height: 112px;
      right: initial;
      left: 0;
    }
  }
}

@media screen and (min-width: $display-width-s) and (max-width: 1300px) {
  /*=================================
  footer
  =================================*/
  .footer {
    .pageTop {
      right: 3%;
    }
  }
}

@media screen and (min-width: 1336px) {
  .fixBnr--article {
    background: $color-yellow;
    border-radius: 0 10px 10px 0;
    position: absolute;
    top: 0;
    right: -80px;
    visibility: hidden;
    opacity: 0;
    @include animation-base(opacity);
    &.show {
      position: fixed;
      top: 280px;
      right: calc(((100% - #{$base-width}) / 2) - 80px);
      z-index: 9999;
      visibility: visible;
      opacity: 1;
    }
    .cvBnr {
      padding: 15px 8px 21px 29px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row-reverse;
      &__text {
        width: 1.6rem;
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1;
        margin-left: 5px;
        display: block;
      }
      &__link {
        width: 2.2rem;
        font-size: 2.2rem;
        font-weight: 700;
        color: #1255FF;
        line-height: 1;
        display: block;
        text-align: center;
        text-decoration: none;
        &::after {
          content: "";
          width: 21px;
          height: 21px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
          background-size: contain;
          margin-top: 10px;
        }
      }
      &::after {
        content: none;
      }
    }
  }
}

@media screen and (min-width: $display-width-s) and (max-width: 1335px) {
  /*=================================
  footer
  =================================*/
  .fixBnr--article {
    background: $color-yellow;
    box-shadow: 0px 5px 15px 0px rgba(27, 27, 27, 0.15);
    border-radius: 10px 0 0 0;
    position: fixed;
    right: -100%;
    bottom: 80px;
    z-index: 9999;
    transition: right 1.2s ease-out;
    &.show {
      right: 0;
    }
    .cvBnr {
      padding: 14px 12px 20px 110px;
      &__text {
        font-size: 1.4rem;
        font-weight: 700;
        line-height: 2;
        display: block;
      }
      &__link {
        font-size: 2.2rem;
        font-weight: 700;
        color: #1255FF;
        line-height: 1;
        display: block;
        text-align: center;
        text-decoration: none;
      }
      &::after {
        width: 102px;
        height: 112px;
        right: initial;
        left: 0;
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  /*=================================
  footer
  =================================*/
  .footer {
    &__cont {
      text-align: center;
      padding: 28px 1%;
    }
    .logo {
      margin: 0 auto 20px;
    }
    .fNavList {
      width: 100%;
    }
    .copy {
      width: 100%;
      display: block;
      margin: 23px 0 0;
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
  footer
  =================================*/
  .footer {
    &Head {
      padding: 38px per(17) 30px;
      display: block;
      .cont--left {
        width: 100%;
      }
      .cont--right {
        width: 100%;
      }
      .infoTtl {
        margin-bottom: 17px;
        width: 100%;
        text-align: center;
      }
      .address {
        padding: 0 per(17);
      }
      .groupSite {
        border-left: none;
        padding: 26px 0 0;
        &__bnr {
          display: flex;
          flex-wrap: wrap;
          a {
            margin: 0 0 per(16) 0;
            width: calc((100% - per(16)) / 2);
            display: block;
            &:nth-child(2n - 1) {
              margin-right: per(16);
            }
          }
        }
      }
    }
    .pageTop {
      width: 80px;
      top: initial;
      right: 0;
      &.float {
        right: 0;
      }
    }
  }
  .fixBnr {
    box-shadow: none;
    border-radius: 0 10px 0 0;
    width: calc(100% - 80px);
    right: initial;
    left: 0;
    bottom: -100%;
    transition: bottom 1s ease-out;
    z-index: 9000;
    &::after {
      content: "";
      width: 21px;
      height: 21px;
      display: inline-block;
      background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
      background-size: contain;
      position: absolute;
      top: 50%;
      right: per(10);
      transform: translateY(-50%);
    }
    &.show {
      bottom: 0;
      right: inherit;
    }
    .cvBnr {
      padding: 14px per(30) 10px 0;
      &__text {
        line-height: 1.2142;
        padding-left: 72px;
        margin: 0 0 4px;
      }
      &__link {
        font-size: 1.9rem;
        line-height: 1.25;
        padding-left: 72px;
        text-align: left;
      }
      &::after {
        width: 74px;
        height: 81px;
        top: initial;
        bottom: 0;
      }
    }
  }
}