@charset "utf-8";

/*=================================
  pager
=================================*/
.pager {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .result {
    font-size: 1.6rem;
    font-weight: 500;
    .num {
      @include font-os;
      font-weight: 700;
      color: $color-theme;
    }
  }
  &List {
    display: flex;
    &__item {
      margin-right: 7px;
      border: 1px solid #D2D2D2;
      border-radius: 4px;
      overflow: hidden;
      a,span {
        font-size: 1.6rem;
        line-height: 1;
        padding: 14px 16px;
        display: block;
      }
      a {
        @include mq-desktop {
          &:hover {
            color: $color-theme;
            background: $color-theme-light;
          }
        }
      }
      span {
        color: $color-theme;
        background: $color-theme-light;
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    pager
  =================================*/
  .pager {
    display: block;
    text-align: center;
    .result {
      font-size: 1.5rem;
      margin-bottom: 10px;
    }
    &List {
      justify-content: center;
      &__item {
        margin-right: 8px;
        a,span {
          padding: 11px 14px;
        }
      }
    }
  }
}