@charset "utf-8";

/*=================================
  title
=================================*/
.ttl--base {
  font-size: 3rem;
  font-weight: 400;
  line-height: 1.8;
  margin-bottom: 56px;
  &.color {
    color: $color-theme;
  }
  &:not(.left) {
    text-align: center;
  }
  &.white {
    color: #fff;
  }
  &.icon {
    &--ranking {
      &::before {
        content: "";
        width: 34px;
        height: 27px;
        display: inline-block;
        background: url(../img/icon/icon_crown.png) no-repeat center center;
        background-size: contain;
        margin: 0 7px 0 0;
      }
    }
    &--area {
      &::before {
        content: "";
        width: 23px;
        height: 32px;
        display: inline-block;
        background: url(../img/icon/icon_pin.png) no-repeat center center;
        background-size: contain;
        margin: 0 12px -4px 0;
      }
    }
    &--category {
      &::before {
        content: "";
        width: 25px;
        height: 35px;
        display: inline-block;
        background: url(../img/icon/icon_kyutouki.png) no-repeat center center;
        background-size: contain;
        margin: 0 7px -2px 0;
      }
    }
    &--faq {
      &::before {
        content: "";
        width: 27px;
        height: 27px;
        display: inline-block;
        background: url(../img/icon/icon_question.png) no-repeat center center;
        background-size: contain;
        margin: 0 7px -2px 0;
      }
    }
    &--pickup {
      &::before {
        content: "";
        width: 26px;
        height: 20px;
        display: inline-block;
        background: url(../img/icon/icon_pickup.png) no-repeat center center;
        background-size: contain;
        margin: 0 10px 0 0;
      }
    }
  }
}
.ttl--page {
  padding: 86px 1%;
  color: #fff;
  background: $color-theme url(../img/bg_pageTtl_01.jpg) no-repeat center center;
  background-size: cover;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  .ttl {
    font-size: 4rem;
    font-weight: 700;
    line-height: 1.7;
    text-align: center;
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    title
  =================================*/
  .ttl--base {
    font-size: 2.2rem;
    line-height: 1.4545;
    margin-bottom: 28px;
    &.icon {
      &--ranking {
        .icon {
          font-size: 2.4rem;
          display: block;
          &::before {
            content: "";
            width: 25px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/icon_crown.png) no-repeat center center;
            background-size: contain;
            margin: 0 5px 0 0;
          }
        }
        &::before {
          content: none;
        }
      }
      &--area {
        &::before {
          width: 19px;
          height: 26px;
          margin: 0 6px -4px 0;
        }
      }
      &--category {
        &::before {
          width: 18px;
          height: 25px;
          margin: 0 9px -2px 0;
        }
      }
      &--faq {
        &::before {
          width: 22px;
          height: 22px;
          margin: 0 7px -2px 0;
        }
      }
      &--pickup {
        &::before {
          width: 17px;
          height: 13px;
          margin: 0 6px 0 0;
        }
      }
    }
  }
  .ttl--page {
    padding: 40px per(17);
    .ttl {
      font-size: 2.6rem;
      line-height: 1.5;
    }
  }
}