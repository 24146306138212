@charset "utf-8";

/*=================================
  table
=================================*/
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: 30px 0 30px;
  width: 100%;
  caption {
    font-size: 1.4rem;
    margin: 20px 0;
    text-align: left;
    caption-side: bottom;
  }
  th {
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    background: #FFF3E8;
    border-top: 1px solid #EC5934;
    border-bottom: 1px solid #EC5934;
  }
  td {
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.25;
    background: #fff;
    border-top: 1px solid #EC5934;
    border-bottom: 1px solid #EC5934;
  }
  th,td {
    padding: 19px 30px;
  }
  &.scroll {
    white-space: nowrap;
    overflow: auto;
    display: block;
    border: none;
    ::-webkit-scrollbar {
      height: 5px;
    }
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    ::-webkit-scrollbar-thumb {
      background: #bcbcbc;
    }
  }
}
.scrollAnnounce {
  display: none;
}

@media screen and (max-width: $display-width-s){
  /*=================================
    table
  =================================*/
  table {
    display: block;
    white-space: nowrap;
    overflow-x: auto;
    margin: 20px 0;
    border: none;
    caption {
      font-size: 1.5rem;
      margin: 15px 0;
      width: 94vw;
    }
    th {
      font-size: 1.5rem;
      padding: 10px;
    }
    td {
      font-size: 1.5rem;
      padding: 10px;
    }
    &::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: none;
      background: #d6d6d6;
    }
    /* スクロールのつまみ部分の設定 */
    &::-webkit-scrollbar-thumb {
      background: $color-yellow;
    }
  }
  .scrollAnnounce {
    display: block;
    text-align: center;
    margin: 20px 0;
    .text {
      font-size: 1.3rem;
      font-weight: 600;
      color: #fff;
      background: $color-theme;
      padding: 3px 6px;
      position: relative;
      &::after {
        content: "";
        border-style: solid;
        border-width: 7px 6.5px 0 6.5px;
        border-color: $color-theme transparent transparent transparent;
        display: inline-block;
        position: absolute;
        bottom: -7px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}