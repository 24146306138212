@charset "utf-8";
/*=================================
  お問い合わせ
=================================*/
.contactWrap {
  padding: 60px 1%;
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    padding: 70px 60px 70px;
    background: #fff;
    @include singleStyle-base;
  }
  .ttl--contact {
    font-size: 3.4rem;
    font-weight: 500;
    color: #fff;
    line-height: 1.05;
    background: $color-theme;
    margin-bottom: 60px;
    margin-left: -60px;
    margin-top: 60px;
    padding: 47px 48px;
    width: calc(100% + 120px);
    &:first-child {
      margin-top: -70px;
    }
  }
  .ttl--form {
    font-size: 2.4rem;
    font-weight: 500;
    line-height: 2;
    margin-top: 64px;
    margin-bottom: 60px;
    padding: 10px 0;
    border-bottom: 2px solid $color-theme;
  }
}
.contactForm {
  background: #F6F6F6;
  padding: 45px 90px 50px;
  .formWrap {
    .step {
      display: flex;
      justify-content: center;
      counter-reset: stepNum;
      margin-bottom: 56px;
      &__item {
        padding: 16px 32px 16px 35px;
        position: relative;
        @include mq-tab {
          padding: vw(16) vw(32) vw(16) vw(35);
        }
        &::after {
          content: "";
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 34px 0 34px 24px;
          border-color: transparent transparent transparent transparent;
          position: absolute;
          top: -1px;
          right: -24px;
          @include mq-tab {
            border-width: vw(34) 0 vw(34) vw(24);
            top: vw(-1);
            right: vw(-24);
          }
        }
        span {
          font-size: 1.8rem;
          font-weight: 500;
          text-align: center;
          @include mq-tab {
            font-size: vw(18);
          }
          &::before {
            counter-increment: stepNum;
            content: counter(stepNum);
            font-size: 1.2rem;
            font-weight: 700;
            color: #fff;
            background: $font-color-base;
            display: inline-block;
            vertical-align: middle;
            border-radius: 50px;
            width: 26px;
            line-height: 26px;
            text-align: center;
            margin-right: 9px;
            @include mq-tab {
              font-size: vw(12);
              width: vw(26);
              line-height: vw(26);
              margin-right: vw(9);
            }
          }
        }
        &.current {
          background: $color-theme-light;
          &::after {
            border-color: transparent transparent transparent $color-theme-light;
          }
          span {
            color: $color-theme;
            &::before {
              background: $color-theme;
            }
          }
        }
        & + .step__item {
          margin-left: 24px;
        }
      }
    }
    .formCont {
      margin-bottom: 40px;
      &__item {
        border-bottom: 1px dashed #888;
        display: flex;
        &:first-child {
          border-top: 1px dashed #888;
        }
      }
      &__ttl {
        font-size: 1.8rem;
        font-weight: 500;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 30px 5px;
        width: 280px;
        .required {
          font-size: 1.5rem;
          font-weight: 500;
          color: #fff;
          background: $color-theme;
          padding: 3px 12px;
        }
      }
      &__text {
        width: calc(100% - 280px);
        padding: 30px 20px;
        input {
          border: 2px solid #ccc;
          background: #fff;
          padding: 13px 20px;
          width: 100%;
        }
        textarea {
          border: 2px solid #ccc;
          background: #fff;
          padding: 13px 20px;
          width: 100%;
        }
      }
    }
    .btnWrap {
      margin-top: 40px;
    }
    .btn--form {
      display: inline-block;
      text-align: center;
      @include mq-desktop {
        &:hover {
          input {
            border-bottom: 6px solid transparent;
            transform: translateY(6px);
          }
        }
      }
      input {
        font-size: 2.2rem;
        font-weight: 700;
        line-height: 1;
        color: #fff;
        min-width: 350px;
        padding: 31px 10px;
        background: $color-theme;
        background-size: 30px auto;
        display: block;
        border-bottom: 6px solid #aa2403;
        cursor: pointer;
      }
      &.back {
        input {
          background: #575757;
          border-bottom: 6px solid #383838;
        }
        @include mq-desktop {
          &:hover {
            input {
              border-bottom: 6px solid transparent;
              transform: translateY(6px);
            }
          }
        }
      }
    }
    &__text {
      font-size: 1.7rem;
      font-weight: 400;
      text-align: center;
      a {
        font-weight: 500;
      }
    }
  }
}
@media screen and (max-width: 920px) {
  /*=================================
    お問い合わせ
  =================================*/
  .contactWrap {
    padding: 50px per(17);
    &__in {
      padding: 50px per(17) 50px;
    }
    .ttl--contact {
      font-size: 2.2rem;
      margin-bottom: 30px;
      margin-left: per(-17);
      margin-top: 50px;
      padding: 15px per(17) 15px calc(per(17) + 32px);
      width: calc(100% + per(34));
      position: relative;
      &:first-child {
        margin-top: -50px;
      }
    }
    .ttl--form {
      font-size: 2rem;
      line-height: 1.23529;
      margin-top: 50px;
      margin-bottom: 50px;
      padding: 8px 0;
    }
  }
  .contactForm {
    padding: 40px per(17);
    .formWrap {
      .step {
        margin-bottom: 30px;
        &__item {
          padding: vw(10,$sp-width);
          &::after {
            content: none;
          }
          span {
            font-size: vw(12,$sp-width);
            &::before {
              font-size: 1.2rem;
              width: 22px;
              line-height: 22px;
              margin: 0 auto 6px;
              display: block;
            }
          }
          & + .step__item {
            margin-left: vw(10,$sp-width);
          }
        }
      }
      .formCont {
        margin-bottom: 40px;
        &__item {
          display: block;
        }
        &__ttl {
          font-size: 1.6rem;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 10px 0;
          width: 100%;
          .required {
            font-size: 1.4rem;
            padding: 3px 10px;
            margin-left: 10px;
          }
        }
        &__text {
          width: 100%;
          padding: 10px 0 20px;
          input {
            padding: 13px per(17);
            width: 100%;
          }
          textarea {
            padding: 13px per(17);
            width: 100%;
          }
        }
      }
      .btnWrap {
        display: block;
      }
      .btn--form {
        display: block;
        input {
          font-size: 1.6rem;
          min-width: initial;
          width: 100%;
          padding: 20px per(17);
          background: url(../img/icon/icon_arrow_w.png) no-repeat right per(17) center $color-theme;
          background-size: 10px auto;
        }
      }
      &__text {
        font-size: 1.6rem;
      }
    }
  }
}