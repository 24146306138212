@charset "utf-8";
/*=================================
  page--area
=================================*/
.areaDetail {
  padding: 30px 1% 0;
  &__in {
    @include singleStyle-base;
    max-width: $base-width;
    margin: 0 auto;
    padding: 38px 70px 48px;
    background: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    @include mq-tab {
      padding: vw(38) vw(70) vw(48);
    }
    .link {
      text-align: right;
      a {
        font-size: 1.5rem;
        position: relative;
        &::after {
          content: "";
          width: 20px;
          height: 20px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
          background-size: contain;
          vertical-align: middle;
          margin: 0 0 0 6px;
        }
      }
    }
    /* 業者 */
    .introItem {
      .contWrap {
        position: relative;
        .thumbWrap {
          margin: 0 0 27px;
          text-align: center;
          img {
            margin: 0;
          }
        }
        .cont {
          &__summary {
            font-size: 2rem;
            line-height: 1.35;
            margin-bottom: 10px;
          }
          &__ttl {
            font-size: 3.2rem;
            line-height: 1.21875;
            color: $color-theme;
          }
          &__list {
            background: $color-theme-light;
            padding: 24px 28px;
            margin-top: 30px;
            &Item {
              font-size: 2rem;
              line-height: 1.7;
              color: $color-theme;
              display: inline-block;
              margin-right: 27px;
              padding-left: 30px;
              position: relative;
              &::before {
                content: "";
                width: 21px;
                height: 16px;
                display: inline-block;
                background: url(../img/icon/icon_check_orange.png) no-repeat center center;
                background-size: contain;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

          }
          &__text {
            font-size: 2rem;
            line-height: 1.85;
            margin-top: 40px;
          }
        }
      }
    }
    /* ボタン */
    .btnWrap.orange {
      width: calc(100% + 140px);
      margin-left: -70px;
      &.end {
        border-radius: 0 0 8px 8px;
        margin-bottom: -48px;
      }
    }
    /* 口コミ */
    .evaluation {
      background: #F6F6F6;
      padding: 40px 46px 30px 53px;
      border-radius: 10px;
      margin-top: 50px;
      margin-bottom: 30px;
      position: relative;
      &::before {
        content: "";
        width: 38px;
        height: 30px;
        display: inline-block;
        background: url(../img/icon/icon_comments.png) no-repeat center center;
        background-size: contain;
        position: absolute;
        top: -9px;
        left: 15px;
      }
      &::after {
        content: "";
        display: inline-block;
        border-style: solid;
        border-width: 23px 16px 0 16px;
        border-color: #F6F6F6 transparent transparent transparent;
        position: absolute;
        left: 50%;
        bottom: -23px;
      }
    }
  }
  @include singleStyle-parts;
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    page--area
  =================================*/
  .areaDetail {
    padding: 30px per(17);
    &__in {
      padding: 30px per(17) 40px;
      border-radius: 6px;
      .link {
        text-align: left;
      }
      /* 業者 */
      .introItem {
        .contWrap {
          .thumbWrap {
            margin: 0 0 20px;
          }
          .cont {
            &__summary {
              font-size: 1.8rem;
              margin-bottom: 8px;
            }
            &__ttl {
              font-size: 2.4rem;
              line-height: 1.625;
            }
            &__list {
              padding: 18px per(22) 28px;
              margin-top: 16px;
              &Item {
                font-size: 1.6rem;
                margin: 10px 0 0;
                display: block;
                padding-left: 28px;
                &::before {
                  width: 18px;
                  height: 14px;
                  top: 6px;
                  transform: translateY(0);
                }
              }

            }
            &__text {
              font-size: 1.6rem;
              line-height: 1.75;
              margin-top: 20px;
            }
          }
        }
      }
      /* ボタン */
      .btnWrap.orange {
        width: calc(100% + per(34) + 4px);
        margin-left: calc(per(-17) - 2px);
        &.end {
          border-radius: 0 0 6px 6px;
          margin-bottom: -40px;
        }
      }
      /* 口コミ */
      .evaluation {
        padding: 30px per(17);
        border-radius: 6px;
        &::before {
          width: 34px;
          height: 26px;
          top: -13px;
          left: per(17);
        }
        &::after {
          border-width: 18px 12px 0 12px;
          bottom: -18px;
        }
      }

    }
  }
}