@charset "utf-8";
/*=================================
  topPage
=================================*/
.home {
  .secWrap {
    padding: 90px 1% 60px;
    &__inner {
      max-width: $base-width;
      margin: 0 auto;
    }
  }
  .category {
    background: $color-theme;
    &List {
      background: $color-theme-light;
      border-radius: 10px;
      padding: 40px 40px 3px;
      display: flex;
      flex-wrap: wrap;
      &__item {
        width: calc((100% - 40px) / 3);
        margin-bottom: 37px;
        border-radius: 10px;
        overflow: hidden;
        position: relative;
        &:not(:nth-child(3n + 1)) {
          margin-left: 20px;
        }
        &::after {
          content: "";
          width: 27px;
          height: 28px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 17px;
          transform: translateY(-50%);
        }
        @include mq-desktop {
          &:hover {
            &::after {
              right: 10px;
            }
            opacity: .8;
          }
        }
      }
      &__link {
        font-size: 2.4rem;
        font-weight: 500;
        line-height: 1.5416;
        color: $font-color-base;
        text-shadow: 0 1px 2px #fff;
        text-decoration: none;
        display: block;
        width: 100%;
        padding: 0 44px;
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      &__bg {
        display: block;
      }
    }
    .keySearch {
      margin: -26px 0 30px;
      .searchBox {
        position: relative;
        border: none;
        .input {
          width: 100%;
          padding: 17px 20px;
          border-radius: 10px;
          background: #F5F5F5;
          &::placeholder {
            font-size: 2rem;
            line-height: 1;
            letter-spacing: 0;
            color: #444;
            background: url(../img/icon/icon_search.png) no-repeat left center;
            background-size: auto 20px;
            padding: 0 0 3px 28px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
        .submit {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 40px;
          cursor: pointer;
        }
      }
    }
  }
  .faq {
    background: url(../img/bg_faq_pc.png) no-repeat right bottom;
    background-size: cover;
    padding: 85px 1% 83px;
    &List {
      background: #fff;
      padding: 37px 40px;
      border-radius: 10px;
      box-shadow: 0 3px 16px rgba(0,0,0,.1);
      .q {
        font-size: 2.4rem;
        font-weight: 400;
        color: $color-theme;
        padding-left: 40px;
        position: relative;
        &::before {
          content: "Q";
          font-size: 1.5rem;
          font-weight: 400;
          color: #fff;
          background: $color-theme;
          border-radius: 50px;
          display: inline-block;
          width: 30px;
          line-height: 30px;
          text-align: center;
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .a {
        font-size: 1.6rem;
        line-height: 1.6875;
        padding: 0 40px;
        margin-top: 18px;
        p + p {
          margin-top: 26px;
        }
        a {
          padding-right: 17px;
          margin: 0 1rem;
          position: relative;
          &::after {
            content: "";
            width: 17px;
            height: 17px;
            display: inline-block;
            background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
          }
        }
      }
      & + .faqList {
        margin-top: 30px;
      }
    }
  }
  .column {
    background: #fff;
    &List {
      &__item {
        border: 1px solid #ddd;
        border-top: 6px solid $color-theme;
        & + .columnList__item {
          margin-top: 20px;
        }
      }
      .column {
        &__ttl {
          font-size: 2rem;
          font-weight: 700;
          padding: 24px 30px 17px;
          border-bottom: 1px solid #ddd;
        }
        &__cont {
          padding: 28px 30px 35px;
        }
        &Link {
          &__item {
            padding-left: 49px;
            position: relative;
            &::before {
              @include font-roboto;
              content: "Q";
              font-size: 1.5rem;
              font-weight: 700;
              color: #fff;
              background: $color-theme;
              border-radius: 50px;
              display: inline-block;
              width: 32px;
              line-height: 32px;
              text-align: center;
              position: absolute;
              top: 0;
              left: 0;
            }
            a {
              font-size: 1.6rem;
            }
            & +.columnLink__item {
              margin-top: 28px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    topPage
  =================================*/
  .home {
    .secWrap {
      padding: 36px per(17);
    }
    .category {
      padding: 35px 0 0;
      &List {
        border-radius: 0;
        padding: 26px per(34) 30px;
        display: block;
        &__item {
          width: 100%;
          margin-bottom: 0;
          border-radius: 8px;
          &:not(:nth-child(3n + 1)) {
            margin-left: 0;
          }
          &::after {
            width: 26px;
            height: 26px;
            right: 16px;
          }
          & + .categoryList__item {
            margin-top: 30px;
          }
        }
      }
      .keySearch {
        margin: -10px 0 30px;
        padding: 0 per(34);
        .searchBox {
          .input {
            width: 100%;
            padding: 17px 20px;
            border-radius: 6px;
            &::placeholder {
              font-size: 1.6rem;
              background-size: auto 17px;
              padding: 0 0 6px 28px;
            }
          }
        }
      }
    }
    .faq {
      background: url(../img/bg_faq_sp.png) no-repeat left top;
      background-size: 100% auto;
      padding: 36px per(17) 40px;
      &List {
        padding: 20px per(17);
        border-radius: 6px;
        .q {
          font-size: 2rem;
          line-height: 1.4;
          &::before {
            font-size: 1.4rem;
            width: 25px;
            line-height: 25px;
            top: 2px;
            transform: translateY(0);
          }
        }
        .a {
          padding: 0;
          margin-top: 15px;
          p + p {
            margin-top: 20px;
          }
        }
        & + .faqList {
          margin-top: 20px;
        }
      }
    }
    .column {
      background: #fff;
      &List {
        &__item {
          border: 1px solid #ddd;
          border-top: 6px solid $color-theme;
          & + .columnList__item {
            margin-top: 20px;
          }
        }
        .column {
          &__ttl {
            font-size: 2rem;
            font-weight: 700;
            padding: 24px 30px 17px;
            border-bottom: 1px solid #ddd;
          }
          &__cont {
            padding: 28px 30px 35px;
          }
          &Link {
            &__item {
              padding-left: 49px;
              position: relative;
              &::before {
                content: "Q";
                font-size: 1.5rem;
                font-weight: 700;
                color: #fff;
                background: $color-theme;
                border-radius: 50px;
                display: inline-block;
                width: 32px;
                line-height: 32px;
                text-align: center;
                position: absolute;
                top: 0;
                left: 0;
              }
              a {
                font-size: 1.6rem;
              }
              & +.columnLink__item {
                margin-top: 28px;
              }
            }
          }
        }
      }
    }
  }
}