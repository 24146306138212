@charset "utf-8";
/*=================================
  ranking
=================================*/
.ranking {
  background: $bg-color;
  padding: 55px 1% 38px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &List {
    counter-reset: rankingNum;
    &__item {
      .contWrap {
        background: #fff;
        padding: 60px 60px 28px;
        border: 1px solid #D2D2D2;
        border-bottom: none;
        border-radius: 8px 8px 0 0;
        position: relative;
        display: flex;
        align-items: flex-start;
        &::before {
          @include font-os;
          content: counter(rankingNum);
          counter-increment: rankingNum;
          font-size: 3.3rem;
          font-weight: 400;
          color: #fff;
          width: 77px;
          height: 83px;
          display: inline-block;
          text-align: center;
          background: $color-theme;
          position: absolute;
          top: 0;
          right: 60px;
        }
        .thumbWrap {
          max-width: 328px;
          text-align: center;
          & + .cont {
            width: calc(100% - (328px + 60px));
            margin-left: 60px;
          }
        }
        .cont {
          &__summary {
            font-size: 1.8rem;
            line-height: 1.5;
            padding-right: 80px;
          }
          &__ttl {
            a {
              font-size: 2.8rem;
              line-height: 1.39285;
              padding-right: 80px;
              &[target="_blank"] {
                position: relative;
                &::after {
                  content: "";
                  width: 13px;
                  height: 12px;
                  display: inline-block;
                  background: url(../img/icon/icon_link.png) no-repeat center center;
                  background-size: contain;
                  margin-left: 5px;
                }
              }
            }
          }
          &__list {
            background: $color-theme-light;
            padding: 12px 17px;
            margin-top: 12px;
            &Item {
              font-size: 1.4rem;
              line-height: 1.71428;
              color: $color-theme;
              display: inline-block;
              margin-right: 12px;
              padding-left: 19px;
              position: relative;
              &::before {
                content: "";
                width: 14px;
                height: 11px;
                display: inline-block;
                background: url(../img/icon/icon_check_orange.png) no-repeat center center;
                background-size: contain;
                position: absolute;
                top: 50%;
                left: 0;
                transform: translateY(-50%);
              }
            }

          }
          &__text {
            font-size: 1.6rem;
            line-height: 1.6875;
            margin-top: 16px;
          }
          &__more {
            font-size: 1.6rem;
            line-height: 2.4375;
            color: $color-blue-dark;
            text-decoration: underline;
            text-align: right;
            &::after {
              content: "";
              width: 20px;
              height: 20px;
              display: inline-block;
              background: url(../img/icon/icon_arrow_r.png) no-repeat 
              center center;
              background-size: contain;
              margin-left: 6px;
              vertical-align: middle;
            }
          }
        }
        @include mq-desktop {
          &:hover {
            .cont__more {
              text-decoration: none;
            }
          }
        }
      }
      .linkWrap {
        background: $color-theme;
        border-radius: 0 0 8px 8px;
        display: flex;
        justify-content: space-between;
        padding: 30px 56px 26px;
        .cv {
          font-size: 3rem;
          font-weight: 700;
          line-height: 1.3;
          background: $color-yellow;
          padding: 25px 63px 25px 60px;
          text-align: center;
          border-radius: 20px;
          text-decoration: none;
          box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
          position: relative;
          width: calc(55% - 17px);
          &::after {
            content: "";
            width: 30px;
            height: 30px;
            display: inline-block;
            background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            right: 33px;
            transform: translateY(-50%);
          }
        }
        .check {
          font-size: 2.2rem;
          font-weight: 700;
          line-height: 1.772727;
          color: $font-color-base;
          background: #fff;
          padding: 25px 100px;
          text-align: center;
          border-radius: 20px;
          text-decoration: none;
          box-shadow: 0 3px 16px rgba(0, 0, 0, .16);
          position: relative;
          width: calc(45% - 17px);
          &::before {
            content: "";
            width: 30px;
            height: 30px;
            display: inline-block;
            background: url(../img/icon/icon_check.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 50%;
            left: 28px;
            transform: translateY(-50%);
          }
        }
        @include mq-desktop {
          a:hover {
            animation: jump .5s ease-in-out;
          }
        }
      }
      @for $i from 1 through 3 {
        &:nth-child(#{$i}) {
          .contWrap::before {
            background: url(../img/rank_0#{$i}.png) no-repeat center top;
            background-size: contain;
          }
        }
      }
      & + .rankingList__item {
        margin-top: 30px;
      }
    }
  }
}

@media screen and (max-width: $display-width-s) {
  /*=================================
    ranking
  =================================*/
  .ranking {
    padding: 37px per(17) 27px;
    &List {
      &__item {
        .contWrap {
          padding: 30px per(17) 28px;
          border-radius: 4px 4px 0 0;
          display: block;
          &::before {
            font-size: 2.2rem;
            width: 53px;
            height: 57px;
            right: per(17);
          }
          .thumbWrap {
            max-width: initial;
            text-align: center;
            & + .cont {
              width: 100%;
              margin: 20px 0 0;
            }
          }
          .cont {
            &__summary {
              padding-right: 0;
            }
            &__ttl {
              a {
                font-size: 2.4rem;
                line-height: 1.625;
                padding-right: 0;
                &[target="_blank"] {
                  &::after {
                    content: "";
                    width: 13px;
                    height: 12px;
                    display: inline-block;
                    background: url(../img/icon/icon_link.png) no-repeat center center;
                    background-size: contain;
                    margin-left: 5px;
                  }
                }
              }
            }
            &__list {
              padding: 18px per(22) 28px;
              margin-top: 16px;
              &Item {
                font-size: 1.6rem;
                margin: 10px 0 0;
                display: block;
                padding-left: 28px;
                &::before {
                  width: 18px;
                  height: 14px;
                  top: 6px;
                  transform: translateY(0);
                }
              }

            }
            &__text {
              line-height: 1.75;
              margin-top: 20px;
            }
          }
        }
        .linkWrap {
          border-radius: 0 0 6px 6px;
          display: block;
          padding: 30px per(17) 28px;
          .cv {
            font-size: 2rem;
            line-height: 1.1;
            padding: 26px per(35) 25px per(16);
            border-radius: 8px;
            display: block;
            width: 100%;
            &::after {
              width: 20px;
              height: 20px;
              right: per(10);
            }
          }
          .check {
            font-size: 2rem;
            line-height: 1.35;
            padding: 25px per(17);
            border-radius: 8px;
            display: block;
            margin-top: 12px;
            width: 100%;
            &::before {
              width: 20px;
              height: 20px;
              left: per(16);
            }
          }
        }
        & + .rankingList__item {
          margin-top: 20px;
        }
      }
    }
  }
}