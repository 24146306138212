@charset "utf-8";

/* =================================
  header
================================= */
.header {
  width: 100%;
  background: #fff;
  &__top {
    max-width: $base-width;
    margin: 0 auto;
    position: relative;
    .ttl--site {
      width: 29%;
      display: block;
      position: absolute;
      top: 20px;
      left: 0;
      .logo {
        display: block;
      }
      @include mq-desktop {
        &:hover {
          .logo {
            opacity: .8;
          }
        }
      }
    }
  }
  .keySearch {
    max-width: calc(#{$base-width} + 2%);
    margin: 0 auto;
    padding: 24px 1%;
    text-align: right;
    form {
      display: inline-block;
    }
    .searchBox {
      width: 264px;
      border: none;
      position: relative;
      .input {
        background: url(../img/icon/icon_search.png) no-repeat left 18px center #F3F3F3;
        background-size: auto 14px;
        border-radius: 50px;
        padding: 6px 50px;
        width: 100%;
        &::placeholder {
          font-size: 1.6rem;
          color: #222;
        }
      }
      .submit {
        width: 40px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        cursor: pointer;
      }
    }
  }
  .gNav {
    &List {
      background: $color-theme-light;
      padding: 15px 0;
      &__inner {
        max-width: $base-width;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
      }
      &__item {
        display: inline-block;
        margin: 0 10px;
        @include mq-desktop {
          &:hover {
            a {
              font-weight: 500;
              color: #fff;
              background: $color-theme;
            }
          }
        }
        a {
          font-size: 1.7rem;
          font-weight: 400;
          color: $font-color-base;
          padding: 19px 29px;
          text-decoration: none;
          display: block;
          background: transparent;
          border-radius: 10px;
        }
      }
    }
  }
}

@media screen and (min-width: 920px) {
  .gNavList {
    &.fixedNav {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      background: #fff;
      box-shadow: 10px 3px 36px rgba(0, 0, 0, 0.1);
      z-index: 999;
      animation: headerFade 1s ease-in-out;
    }
  }
}
@media screen and (max-width: 920px) {
  /* =================================
    header
  ================================= */
  .header {
    padding: 0;
    box-shadow: none;
    position: relative;
    z-index: 9999;
    &__top {
      padding: 13px 17px;
      background: #fff;
      .ttl--site {
        width: 80%;
        max-width: 222px;
        position: static;
      }
    }
    .keySearch {
      max-width: initial;
      padding: 20px per(34);
      text-align: center;
      background: #fff;
      border-top: 1px solid #D2D2D2;
      form {
        display: block;
      }
      .searchBox {
        width: 100%;
        .input {
          background: url(../img/icon/icon_search.png) no-repeat left 22px center #F3F3F3;
          background-size: auto 17px;
          padding: 15px 50px;
          width: 100%;
          &::placeholder {
            font-size: 1.6rem;
            color: #222;
          }
        }
        .submit {
          width: 40px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          cursor: pointer;
        }
      }
    }
    .gNav {
      overflow-y: scroll;
      max-height: 90vh;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
      transform: scale(.7);
      opacity: 0;
      visibility: hidden;
      transition: opacity .5s ease-in-out, transform .3s ease-out,visibility .2s ease-out;
      &List {
        padding: per(30) per(17) per(10);
        &__item {
          display: block;
          width: calc((100% - per(20)) / 2);
          margin: 0 0 per(20);
          a {
            font-weight: 600;
            color: #fff;
            padding: 22px;
            text-decoration: none;
            display: block;
            background: $color-theme;
            border-radius: 6px;
            text-align: center;
          }
          &:nth-child(2n) {
            margin-left: per(20);
          }
        }
      }
      &.show {
        transform: scale(1);

        visibility: visible;
        opacity: 1;
      }
      &.hide {
        transform: scale(.7);
        opacity: 0;
        visibility: hidden;
      }
      .logo--sub {
        width: calc(100% - 60px);
        max-width: 222px;
        padding: 12px 17px;
        box-sizing: content-box;
      }
    }
    .headerNav__cover {
      height: 0;
      width: 100vw;
      background: #fff;
      background-size: cover;
      position: fixed;
      top: 0;
      left: 0;
      display: none;
      z-index: 9998;
      &.show {
        display: block;
        animation: show .2s linear 0s;
      }
      &.hide {
        display: none;
        animation: hide .2s linear 0s;
      }
    }

    /*バーガーボタン設定*/
    .burger {
      width: 60px;
      height: 60px;
      background: #fff;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
      cursor: pointer;
      z-index: 9999;
      @include animation-base(left,.3s);
      span {
        width: 27px;
        height: 3px;
        display: block;
        background: #222;
        position: absolute;
        top: 21px;
        right: 0;
        left: 0;
        margin: 0 auto;
        transition: width 0.2s, right 0.2s, left 0.2s;
        &.burger--top{
          transform: translateY(0);
        }
        &.burger--middle{
          transform: translateY(7px);
          position: relative;
          background: none;
          &::before{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #222;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
          &::after{
            content: "";
            display: block;
            position: absolute;
            width: 27px;
            height: 3px;
            background: #222;
            transition: all 0.2s;
            transform: rotate(0deg);
          }
        }
        &.burger--bottom{
          transform: translateY(14px);
        }
      }
      /*クリック後、バツボタンになる*/
      &.is-open {
        background: $color-theme;
        .burger--top{
          left: 100%;
          width: 0px;
        }
        .burger--middle {
          &::before{
            transform: rotate(135deg);
            background: #fff;
          }
          &::after {
            transform: rotate(45deg);
            background: #fff;
          }
        }
        .burger--bottom{
          right: 100%;
          width: 0px;
        }
      }
      &.show {
        position: fixed;
        animation: burger-show 1s ease-in;
      }
    }
  }
}