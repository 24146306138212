@charset "utf-8";

/*=================================
  breadcrumb
=================================*/
.breadcrumb {
  background: #fff;
  ul {
    li {
      font-size: 1.2rem;
      line-height: 1;
      display: inline-block;
      position: relative;
      a,span {
        font-size: 1.2rem;
        color: $font-color-base;
        line-height: 1;
        display: block;
        padding: 19px 0;
      }
      & + li {
        padding-left: 20px;
        &::before {
          content: "";
          width: 6px;
          height: 9px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_o.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 51%;
          left: 6px;
          transform: translateY(-50%);
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s){
  /*=================================
    breadcrumb
  =================================*/
  .breadcrumb {
    overflow-x: scroll;
    white-space: nowrap;
    padding: 0 per(17) 15px;
    ul {
      li {
        a,span {
          padding: 8px 6px 10px;
        }
        & + li {
          padding-left: 15px;
          &::before {
            content: "";
            width: 6px;
            height: 9px;
            display: inline-block;
            background: url(../img/icon/icon_arrow_o.png) no-repeat center center;
            background-size: contain;
            position: absolute;
            top: 51%;
            left: 4px;
            transform: translateY(-50%);
          }
        }
      }
    }
  }
}