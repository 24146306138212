@charset "utf-8";
/*=================================
  area
=================================*/
.area {
  background: $bg-color;
  padding: 38px 1% 70px;
  &__inner {
    max-width: $base-width;
    margin: 0 auto;
  }
  &__cont {
    display: flex;
    flex-wrap: wrap;
  }
  .areaList {
    width: calc((100% - 30px) / 2);
    border-radius: 10px;
    border: 1px solid #D2D2D2;
    overflow: hidden;
    padding-top: 150px;
    margin-bottom: 30px;
    @include mq-tab {
      width: calc((100% - vw(30)) / 2);
      padding-top: vw(150);
      margin-bottom: vw(30);
    }
    &:nth-child(2n) {
      margin-left: 30px;
      @include mq-tab {
        margin-left: vw(30);
      }
    }
    $areaList: tohoku, kanto, tokai, kansai, chugoku, kyushu;
    @each $area in $areaList {
      &.#{$area} {
        background: url(../img/bg_#{$area}_pc.jpg) no-repeat center top #fff;
        background-size: auto;
      }
    }
    &__item {
      border-top: 1px solid #D2D2D2;
      padding: 27px 30px;
      background: #fff;
      @include mq-tab {
        padding: vw(27) vw(30);
      }
    }
    &__name {
      font-size: 1.7rem;
      line-height: 1;
      margin-bottom: 11px;
    }
    &__cont {
      .prefecture {
        &__item {
          display: inline-block;
          a {
            font-size: 1.5rem;
            line-height: 1.8;
            display: block;
            text-decoration: none;
            padding-right: 2rem;
            margin-right: 2rem;
            position: relative;
          }
          @include mq-desktop {
            &:hover {
              a {
                text-decoration: underline;
                &::after {
                  content: "";
                  width: 15px;
                  height: 15px;
                  display: inline-block;
                  background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
                  background-size: contain;
                  position: absolute;
                  top: 50%;
                  right: 0;
                  transform: translateY(-50%);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: $display-width-s) {
  /*=================================
    area
  =================================*/
  .area {
    padding: 0 per(17) 23px;
    &__cont {
      display: block;
    }
    .areaList {
      width: 100%;
      border-radius: 8px;
      padding-top: 0;
      margin-bottom: 17px;
      position: relative;
      &:nth-child(2n) {
        margin-left: 0;
      }
      $areaList: tohoku, kanto, tokai, kansai, chugoku, kyushu;
      @each $area in $areaList {
        &.#{$area} {
          background: none;
        }
      }
      @each $area in $areaList {
        &.#{$area} .areaList__name {
          background: url(../img/bg_#{$area}_sp.jpg) no-repeat center top #fff;
          background-size: auto;
        }
      }
      &__item {
        border-top: none;
        padding: 0;
      }
      &__name {
        font-size: 2rem;
        font-weight: 600;
        color: #fff;
        margin-bottom: 0;
        padding: 30px 17px;
        position: relative;
        &::after {
          content: "";
          width: 20px;
          height: 12px;
          display: inline-block;
          background: url(../img/icon/icon_arrow_w.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 50%;
          right: 27px;
          transform: translateY(-50%);
        }
      }
      &__cont {
        .prefecture {
          display: flex;
          flex-wrap: wrap;
          position: relative;
          &__item {
            width: calc(100% / 2);
            display: block;
            border-top: 1px solid #D2D2D2;
            &:nth-child(2n - 1) {
              border-right: 1px solid #D2D2D2;
              position: relative;
              &:last-child {
                &::after {
                  content: "";
                  width: calc(100% + 2px);
                  height: calc(100% + 2px);
                  display: block;
                  position: absolute;
                  top: -1%;
                  right: -101%;
                  border-top: 1px solid #D2D2D2;
                }
              }
            }
            a {
              font-size: 1.7rem;
              line-height: 1;
              padding: 20px 17px;
              margin-right: 0;
              position: relative;
              &::after {
                content: "";
                width: 15px;
                height: 15px;
                display: inline-block;
                background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
                background-size: contain;
                position: absolute;
                right: 17px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }
        }
      }
    }
    .js-area-pulldown {
      &-item {
        display: none;
      }
      &.open {
        &::after {
          transform: translateY(-50%) rotate(180deg);
        }
      }
    }
  }
}