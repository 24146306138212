@charset "utf-8";
/*=================================
  page--article
=================================*/
@mixin singleStyle-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.6875;
    & + p:not([class]) {
      margin-top: 20px;
    }
  }
  h1:not([class]) {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    background: $color-theme;
    margin-top: 70px;
    margin-bottom: 40px;
    margin-left: -70px;
    padding: 32px 70px;
    width: calc(100% + 140px);
  }
  h2:not([class]) {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.23529;
    margin-top: 70px;
    margin-bottom: 16px;
    padding: 7px 0 7px  14px;
    border-left: 4px solid $color-theme;
    position: relative;
  }
  h3:not([class]) {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    padding-bottom: 15px;
    margin-top: 70px;
    margin-bottom: 30px;
    border-bottom: 2px solid #D2D2D2;
    position: relative;
    &::after {
      content: "";
      width: 25%;
      height: 2px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      bottom: -2px;
    }
  }
  h4:not([class]) {
    font-size: 1.8rem;
    font-weight: 600;
    color: $color-theme;
    line-height: 1.66666;
    padding: 15px 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
  }
  h5:not([class]) {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.44444;
    background: #F6F6F6;
    padding: 12px 20px;
    margin-top: 60px;
    margin-bottom: 30px;
    border-left: 4px solid $color-theme;
  }
  h6:not([class]) {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.44444;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
  blockquote {
    position: relative;
    padding: 50px 20px 20px;
    margin: 50px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    padding: 37px 32px;
    background: #F6F6F6;
    &::before {
      content: "";
      width: 28px;
      height: 24px;
      background: url(../img/icon/icon_quote.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: -12px;
      left: 22px;
    }
    &::after {
      content: "";
      width: 28px;
      height: 24px;
      background: url(../img/icon/icon_quote.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      bottom: -12px;
      right: 22px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        margin: 0 0 20px!important;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: .06em;
        position: relative;
      }
    }
    .text {
      border-left: 2px solid #D2D2D2;
      padding-left: 22px;
    }
    p {
      font-style: normal;
      line-height: 2.1875!important;
      & + p {
        margin-top: 50px!important;
      }
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 15px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]) {
    display: block;
    margin: 15px auto 20px;
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
  }
  > a[target="_blank"]::after {
    content: "";
    width: 13px;
    height: 12px;
    display: inline-block;
    background: url(../img/icon/icon_link.png) no-repeat center center;
    background-size: contain;
    margin-left: 5px;
  }
  ul:not([class]) {
    margin: 40px 0;
    padding: 30px vw(40);
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.625;
      padding-left: 38px;
      position: relative;
      &::before {
        content: "";
        width: 25px;
        height: 25px;
        background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
      }
      & + li {
        margin-top: 20px;
      }
    }
    h6::before {
      content: none;
    }
  }
  ol:not([class]) {
    margin: 40px 0;
    padding: 30px vw(40);
    background: $color-theme-light;
    border-radius: 10px;
    counter-reset: listNum;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.625;
      padding-left: 34px;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        @include font-roboto;
        font-size: 1.2rem;
        font-weight: 700;
        color: #fff;
        width: 26px;
        line-height: 26px;
        text-align: center;
        background: $color-theme;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }
      & + li {
        margin-top: 20px;
      }
    }
    h6::before {
      content: none;
    }
  }
  @include mq-sp {
    p:not([class]) {
      font-size: 1.5rem;
      line-height: 1.6875;
      & + p:not([class]) {
        margin-top: 20px;
      }
    }
    h1:not([class]) {
      font-size: 2.2rem;
      line-height: 1.3;
      margin-top: 50px;
      margin-bottom: 30px;
      margin-left: calc(per(-17) - 2px);
      padding: 15px per(17);
      width: calc(100% + per(34) + 4px);
    }
    h2:not([class]) {
      font-size: 2rem;
      margin-top: 50px;
      margin-bottom: 20px;
      padding: 4px 0 4px  12px;
    }
    h3:not([class]) {
      font-size: 1.8rem;
      line-height: 1.3;
      padding-bottom: 12px;
      margin-top: 50px;
    }
    h4:not([class]) {
      font-size: 1.7rem;
      line-height: 1.3;
      padding: 10px per(17);
    }
    h5:not([class]) {
      font-size: 1.6rem;
      line-height: 1.3;
      padding: 12px per(17);
      margin-top: 50px;
    }
    h6:not([class]) {
      font-size: 1.7rem;
    }
    blockquote {
      padding: 30px per(17);
      &::before {
        width: 24px;
        height: 20px;
        top: -10px;
        left: per(17);
      }
      &::after {
        width: 24px;
        height: 20px;
        bottom: -10px;
        right: per(17);
      }
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: 1.5rem;
        }
      }
      .text {
        padding-left: per(17);
      }
      p {
        line-height: 2!important;
        & + p {
          margin-top: 20px!important;
        }
      }
      cite {
        text-align: left;
      }
    }
    img {
      &.alignleft {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
      &.alignright {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
    }
    ul:not([class]) {
      margin: 30px 0;
      padding: 20px per(17);
      border-radius: 6px;
      li {
        font-size: 1.5rem;
        padding-left: 28px;
        &::before {
          width: 20px;
          height: 20px;
          top: 3px;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
    ol:not([class]) {
      margin: 30px 0;
      padding: 20px per(17);
      border-radius: 6px;
      li {
        font-size: 1.5rem;
        padding-left: 30px;
        &::before {
          width: 22px;
          line-height: 22px;
          top: 2px;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}
@mixin singleStyle-parts {
  /*---------------------
    記事タイトル
  ---------------------*/
  .articleTtl {
    font-size: 3.6rem;
    font-weight: 600;
    line-height: 1.38888;
  }
  /*---------------------
    日付
  ---------------------*/
  .articleDate {
    @include font-os;
    font-size: 1.5rem;
    font-weight: 700;
    color: $color-theme;
    line-height: 1.46666;
    margin-bottom: 10px;
  }
  /*---------------------
    メインビジュアル
  ---------------------*/
  .articleMv {
    margin: 18px auto 16px;
    text-align: center;
  }
  /*---------------------
    目次
  ---------------------*/
  .tocBox {
    border-radius: 10px;
    border: 1px solid #D2D2D2;
    margin: 32px 0 110px;
    padding: 27px vw(40) 30px;
    &__ttl {
      font-size: 2rem;
      font-weight: 600;
      line-height: 1.35;
      margin-bottom: 10px;
      text-align: center;
    }
    .tocList {
      counter-reset: tocNum;
      &__item {
        position: relative;
        &::before {
          counter-increment: tocNum;
          content: counter(tocNum)".";
          font-size: 1.6rem;
          font-weight: 400;
          display: inline-block;
          position: absolute;
          top: 0;
          left: 0;
        }
        a {
          font-size: 1.6rem;
          display: inline-block;
          padding: 0 0 0 24px;
        }
        & + .tocList__item {
          margin-top: 10px;
        }
        .tocList {
          counter-reset: tocChildNum;
          padding-left: 26px;
          &__item {
            &::before {
              counter-increment: tocChildNum;
              content: counter(tocNum) " - " counter(tocChildNum)".";
            }
            a {
              padding-left: 46px;
            }
            & + .tocList__item {
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
  /*---------------------
    ポイント
  ---------------------*/
  .point {
    padding: 37px vw(40);
    background: $color-theme-light;
    border: 1px solid $color-theme;
    margin: 114px 0 30px;
    position: relative;
    &__label {
      font-size: 1.7rem;
      font-weight: 400;
      line-height: 1;
      text-align: center;
      color: #fff;
      background: $color-theme;
      padding: 14px 54px;
      position: absolute;
      top: -44px;
      left: -1px;
      &::before {
        content: "";
        width: 15px;
        height: 17px;
        display: inline-block;
        background: url(../img/icon/icon_hand.png) no-repeat center center;
        background-size: contain;
        margin: 0 4px -2px 0;
      }
    }
    &__cont {
      p {
        padding-left: 38px;
        position: relative;
        &::before {
          content: "";
          width: 25px;
          height: 25px;
          display: inline-block;
          background: url(../img/icon/icon_check_circle.png) no-repeat center center;
          background-size: contain;
          position: absolute;
          top: 4px;
          left: 0;
        }
      }
    }
  }
  /*---------------------
    関連記事
  ---------------------*/
  .articleList {
    margin: 36px 0 67px;
    &__item {
      .itemWrap {
        .thumb {
          position: relative;
          &::after {
            content: "";
            width: 100%;
            height: 100%;
            display: block;
            background: url(../img/related_cover.png) no-repeat center center;
            background-size: cover;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
      & + .articleList__item {
        margin-top: 15px;
      }
    }
  }

  @include mq-sp {
    /*---------------------
      記事タイトル
    ---------------------*/
    .articleTtl {
      font-size: 2.2rem;
      line-height: 1.4;
    }
    /*---------------------
      日付
    ---------------------*/
    .articleDate {
      font-size: 1.3rem;
      margin-bottom: 15px;
    }
    /*---------------------
      メインビジュアル
    ---------------------*/
    .articleMv {
      margin: 20px auto 30px;
    }
    /*---------------------
      目次
    ---------------------*/
    .tocBox {
      border-radius: 6px;
      margin: 50px 0 30px;
      padding: 30px per(17);
      &__ttl {
        font-size: 1.7rem;
        line-height: 1.3;
        margin-bottom: 15px;
      }
      .tocList {
        &__item {
          &::before {
            font-size: 1.4rem;
          }
          a {
            font-size: 1.5rem;
            padding: 0 0 0 20px;
          }
          .tocList {
            padding-left: 20px;
            margin-top: 10px;
            &__item {
              a {
                padding-left: 40px;
              }
            }
          }
        }
      }
    }
  }
}

@mixin articleList-base {
  h1,h2,h3,h4,h5,h6 {
    clear: both;
  }
  p:not([class]) {
    font-size: 1.6rem;
    line-height: 1.6875;
    & + p:not([class]) {
      margin-top: 20px;
    }
  }
  h1:not([class]) {
    font-size: 3rem;
    font-weight: 700;
    color: #fff;
    line-height: 1.2;
    background: $color-theme;
    margin-top: 70px;
    margin-bottom: 40px;
    margin-left: -70px;
    padding: 32px 70px;
    width: calc(100% + 140px);
  }
  h2:not([class]) {
    font-size: 2.6rem;
    font-weight: 600;
    line-height: 1.23529;
    margin-top: 70px;
    margin-bottom: 16px;
    padding: 7px 0 7px  14px;
    border-left: 4px solid $color-theme;
    position: relative;
  }
  h3:not([class]) {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    padding-bottom: 15px;
    margin-top: 70px;
    margin-bottom: 30px;
    border-bottom: 2px solid #D2D2D2;
    position: relative;
    &::after {
      content: "";
      width: 25%;
      height: 2px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      bottom: -2px;
    }
  }
  h4:not([class]) {
    font-size: 1.8rem;
    font-weight: 600;
    color: $color-theme;
    line-height: 1.66666;
    padding: 15px 30px;
    margin-top: 50px;
    margin-bottom: 30px;
    border-top: 1px solid #D2D2D2;
    border-bottom: 1px solid #D2D2D2;
  }
  h5:not([class]) {
    font-size: 1.7rem;
    font-weight: 600;
    line-height: 1.44444;
    background: #F6F6F6;
    padding: 12px 20px;
    margin-top: 60px;
    margin-bottom: 30px;
    border-left: 4px solid $color-theme;
  }
  h6:not([class]) {
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 1.44444;
    margin-top: 30px;
    margin-bottom: 20px;
    padding-left: 20px;
    position: relative;
    &::before {
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background: $color-theme;
      position: absolute;
      left: 0;
      top: 8px;
    }
  }
  blockquote {
    position: relative;
    padding: 50px 20px 20px;
    margin: 50px 0 30px;
    box-sizing: border-box;
    font-style: italic;
    padding: 37px 32px;
    background: #F6F6F6;
    &::before {
      content: "";
      width: 28px;
      height: 24px;
      background: url(../img/icon/icon_quote.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      top: -12px;
      left: 22px;
    }
    &::after {
      content: "";
      width: 28px;
      height: 24px;
      background: url(../img/icon/icon_quote.png) no-repeat center center;
      background-size: contain;
      display: inline-block;
      position: absolute;
      bottom: -12px;
      right: 22px;
    }
    @for $i from 1 through 6 {
      h#{$i}{
        margin: 0 0 20px!important;
        font-size: 1.6rem;
        line-height: 1.75;
        letter-spacing: .06em;
        position: relative;
      }
    }
    .text {
      border-left: 2px solid #D2D2D2;
      padding-left: 22px;
    }
    p {
      font-style: normal;
      line-height: 2.1875!important;
      & + p {
        margin-top: 50px!important;
      }
    }
    cite {
      display: block;
      text-align: right;
      line-height: 1;
      color: #888888;
      font-size: 0.9em;
      margin: 15px 0 0;
    }
  }
  .btnWrap {
    clear: both;
  }
  img:not([class]) {
    display: block;
    margin: 15px auto 20px;
  }
  img {
    &.alignleft {
      float: left;
      margin: 0 30px 30px 0;
    }
    &.alignright {
      float: right;
      margin: 0 0 30px 30px;
    }
  }
  > a[target="_blank"]::after {
    content: "";
    width: 13px;
    height: 12px;
    display: inline-block;
    background: url(../img/icon/icon_link.png) no-repeat center center;
    background-size: contain;
    margin-left: 5px;
  }
  ul:not([class]) {
    margin: 40px 0;
    padding: 30px vw(40);
    border: 1px solid #D2D2D2;
    border-radius: 10px;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.625;
      padding-left: 38px;
      position: relative;
      &::before {
        content: "";
        width: 25px;
        height: 25px;
        background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
        background-size: contain;
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
      }
      & + li {
        margin-top: 20px;
      }
    }
    h6::before {
      content: none;
    }
  }
  ol:not([class]) {
    margin: 40px 0;
    padding: 30px vw(40);
    background: $color-theme-light;
    border-radius: 10px;
    counter-reset: listNum;
    clear: both;
    li {
      font-size: 1.6rem;
      font-weight: 400;
      line-height: 1.625;
      padding-left: 34px;
      position: relative;
      &::before {
        counter-increment: listNum;
        content: counter(listNum);
        @include font-roboto;
        font-size: 1.2rem;
        font-weight: 700;
        color: #fff;
        width: 26px;
        line-height: 26px;
        text-align: center;
        background: $color-theme;
        display: inline-block;
        border-radius: 50%;
        position: absolute;
        top: 0;
        left: 0;
      }
      & + li {
        margin-top: 20px;
      }
    }
    h6::before {
      content: none;
    }
  }
  @include mq-sp {
    p:not([class]) {
      font-size: 1.5rem;
      line-height: 1.6875;
      & + p:not([class]) {
        margin-top: 20px;
      }
    }
    h1:not([class]) {
      font-size: 2.2rem;
      line-height: 1.3;
      margin-top: 50px;
      margin-bottom: 30px;
      margin-left: calc(per(-17) - 2px);
      padding: 15px per(17);
      width: calc(100% + per(34) + 4px);
    }
    h2:not([class]) {
      font-size: 2rem;
      margin-top: 50px;
      margin-bottom: 20px;
      padding: 4px 0 4px  12px;
    }
    h3:not([class]) {
      font-size: 1.8rem;
      line-height: 1.3;
      padding-bottom: 12px;
      margin-top: 50px;
    }
    h4:not([class]) {
      font-size: 1.7rem;
      line-height: 1.3;
      padding: 10px per(17);
    }
    h5:not([class]) {
      font-size: 1.6rem;
      line-height: 1.3;
      padding: 12px per(17);
      margin-top: 50px;
    }
    h6:not([class]) {
      font-size: 1.7rem;
    }
    blockquote {
      padding: 30px per(17);
      &::before {
        width: 24px;
        height: 20px;
        top: -10px;
        left: per(17);
      }
      &::after {
        width: 24px;
        height: 20px;
        bottom: -10px;
        right: per(17);
      }
      @for $i from 1 through 6 {
        h#{$i}{
          font-size: 1.5rem;
        }
      }
      .text {
        padding-left: per(17);
      }
      p {
        line-height: 2!important;
        & + p {
          margin-top: 20px!important;
        }
      }
      cite {
        text-align: left;
      }
    }
    img {
      &.alignleft {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
      &.alignright {
        float: none;
        display: block;
        margin: 15px auto 20px;
      }
    }
    ul:not([class]) {
      margin: 30px 0;
      padding: 20px per(17);
      border-radius: 6px;
      li {
        font-size: 1.5rem;
        padding-left: 28px;
        &::before {
          width: 20px;
          height: 20px;
          top: 3px;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
    ol:not([class]) {
      margin: 30px 0;
      padding: 20px per(17);
      border-radius: 6px;
      li {
        font-size: 1.5rem;
        padding-left: 30px;
        &::before {
          width: 22px;
          line-height: 22px;
          top: 2px;
        }
        & + li {
          margin-top: 15px;
        }
      }
    }
  }
  iframe {
    width: 100%;
  }
}

/* 記事一覧 */
.articleList {
  &__item {
    border-radius: 10px;
    overflow: hidden;
    .itemWrap {
      display: flex;
      flex-wrap: wrap;
      background: #FFEFE1;
      .thumb {
        width: 392px;
        img {
          margin: 0 auto;
          width: 100%;
        }
        & + .textWrap {
          width: calc(100% - 392px);
        }
      }
      .textWrap {
        width: 100%;
        padding: 24px 30px 16px 40px;
        position: relative;
        &__date {
          @include font-os;
          font-size: 1.3rem;
          font-weight: 700;
          line-height: 1;
          color: $color-theme;
          display: block;
          margin-bottom: 7px;
        }
        &__ttl {
          font-size: 1.9rem;
          font-weight: 400;
          line-height: 1.31578;
          display: block;
          margin-bottom: 14px;
        }
        &__text {
          font-size: 1.6rem;
          line-height: 1.5625;
          margin-top: 14px;
          padding-bottom: 23px;
        }
      }
      .moreBtn {
        width: 100%;
        text-align: right;
        position: absolute;
        right: 30px;
        bottom: 18px;
        .more {
          font-size: 1.6rem;
          font-weight: 500;
          color: $color-blue-dark;
          text-decoration: underline;
          display: inline-block;
          &::after {
            content: "";
            width: 20px;
            height: 20px;
            display: inline-block;
            background: url(../img/icon/icon_arrow_r.png) no-repeat center center;
            background-size: contain;
            vertical-align: middle;
            margin: 0 0 4px 6px;
          }
        }
      }
    }
    @include mq-desktop {
      &:hover {
        .itemWrap {
          .textWrap {
            &__ttl {
              color: $color-theme;
            }
          }
        }
        .moreBtn {
          .more {
            color: $color-theme;
            text-decoration: none;
          }
        }
      }
    }
    & + .articleList__item {
      margin-top: 30px;
    }
  }
  &.col--2 {
    display: flex;
    flex-wrap: wrap;
    .articleList__item {
      border-radius: 0;
      border-bottom: 1px solid #D2D2D2;
      padding-bottom: 37px;
      margin-bottom: 40px;
      width: calc((100% - 30px) / 2);
      .itemWrap {
        align-items: flex-start;
        background: transparent;
        .thumb {
          width: 200px;
          margin-right: 30px;
          & + .textWrap {
            width: calc(100% - (200px + 30px));
          }
        }
        .textWrap {
          padding: 0;
          &__date {
            font-size: 1.2rem;
          }
          &__ttl {
            font-size: 1.7rem;
            line-height: 1.4705;
            margin-bottom: 2px;
          }
          &__text {
            font-size: 1.5rem;
            line-height: 1.66666;
            margin-top: 2px;
            padding-bottom: 0;
          }
        }
      }
      @include mq-desktop {
        &:hover {
          .itemWrap {
            .textWrap {
              &__ttl {
                color: $color-theme;
              }
            }
          }
          .moreBtn {
            .more {
              color: $color-theme;
              text-decoration: none;
            }
          }
        }
      }
      & + .articleList__item {
        margin-top: 0;
      }
      &:nth-child(2n) {
        margin-left: 30px;
      }
    }
  }
}

.articleListWrap {
  padding: 30px 1% 0;
  &__in {
    @include articleList-base;
    max-width: $base-width;
    margin: 0 auto;
    padding: 38px 70px 48px;
    background: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    @include mq-tab {
      padding: vw(38) vw(70) vw(48);
    }
  }
  .articleList {
    margin: 44px 0 30px;
  }
}

/* singlePage */
.singlePage {
  padding: 30px 1% 0;
  &__in {
    max-width: $base-width;
    margin: 0 auto;
    padding: 38px 70px 48px;
    background: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    @include singleStyle-base;
    @include mq-tab {
      padding: vw(38) vw(70) vw(48);
    }
    @for $i from 1 through 6 {
      & > h#{$i}:first-child {
        margin-top: 0!important;
      }
    }
  }
  @include singleStyle-parts;
}

/* 記事詳細ページ */
.articleDetail {
  padding: 30px 1% 0;
  &__in {
    @include singleStyle-base;
    max-width: $base-width;
    margin: 0 auto;
    padding: 38px 70px 48px;
    background: #fff;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    position: relative;
    @include mq-tab {
      padding: vw(38) vw(70) vw(48);
    }
    .tocBox {
      & + * {
        margin-top: 0!important;
      }
    }
  }
  @include singleStyle-parts;
}
.blockquote {
  position: relative;
  padding: 50px 20px 20px;
  margin: 50px 0 30px;
  box-sizing: border-box;
  font-style: italic;
  padding: 37px 32px;
  background: #F6F6F6;
  &::before {
    content: "";
    width: 28px;
    height: 24px;
    background: url(../img/icon/icon_quote.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    position: absolute;
    top: -12px;
    left: 22px;
  }
  &::after {
    content: "";
    width: 28px;
    height: 24px;
    background: url(../img/icon/icon_quote.png) no-repeat center center;
    background-size: contain;
    display: inline-block;
    position: absolute;
    bottom: -12px;
    right: 22px;
  }
  @for $i from 1 through 6 {
    h#{$i}{
      margin: 0 0 20px!important;
      font-size: 1.6rem;
      line-height: 1.75;
      letter-spacing: .06em;
      position: relative;
    }
  }
  .text {
    border-left: 2px solid #D2D2D2;
    padding-left: 22px;
  }
  p {
    font-style: normal;
    line-height: 2.1875!important;
    & + p {
      margin-top: 50px!important;
    }
  }
  cite {
    display: block;
    text-align: right;
    line-height: 1;
    color: #888888;
    font-size: 0.9em;
    margin: 15px 0 0;
  }
}

@media screen and (max-width: $display-width-s) {
  /* singlePage */
  .singlePage {
    padding: 20px per(17) 47px;
    &__in {
      padding: 20px per(17) 47px;
    }
  }

  /* 記事詳細ページ */
  .articleDetail {
    padding: 30px per(17) 0;
    &__in {
      padding: 30px per(17) 40px;
      border-radius: 6px;
    }
  }
  .blockquote {
    padding: 30px per(17);
    &::before {
      width: 24px;
      height: 20px;
      top: -10px;
      left: per(17);
    }
    &::after {
      width: 24px;
      height: 20px;
      bottom: -10px;
      right: per(17);
    }
    @for $i from 1 through 6 {
      h#{$i}{
        font-size: 1.5rem;
      }
    }
    .text {
      padding-left: per(17);
    }
    p {
      line-height: 2!important;
      & + p {
        margin-top: 20px!important;
      }
    }
    cite {
      text-align: left;
    }
  }

  /* 記事一覧 */
  .articleList {
    &__item {
      border-radius: 8px;
      .itemWrap {
        display: block;
        .thumb {
          width: 100%;
          & + .textWrap {
            width: 100%;
          }
        }
        .textWrap {
          width: 100%;
          padding: 16px per(20);
          &__text {
            font-size: 1.6rem;
            line-height: 1.75;
            margin-top: 0;
            padding-bottom: 14px;
          }

        }
        .moreBtn {
          width: 100%;
          position: static;
          .more {
            font-size: 1.4rem;
            &::after {
              width: 16px;
              height: 16px;
              margin: 0 0 2px 4px;
            }
          }
        }
      }
      & + .articleList__item {
        margin-top: 20px;
      }
    }
    &.col--2 {
      display: flex;
      .articleList__item {
        padding-bottom: 16px;
        margin-bottom: 30px;
        width: calc((100% - per(24)) / 2);
        .itemWrap {
          .thumb {
            width: 100%;
            margin: 0 0 15px;
            & + .textWrap {
              width: 100%;
            }
          }
          .textWrap {
            &__ttl {
              font-size: 1.8rem;
              line-height: 1.5555;
              margin-bottom: 10px;
            }
            &__text {
              font-size: 1.6rem;
              line-height: 1.75;
              margin-top: 0;
            }
          }
        }
        &:nth-child(2n) {
          margin-left: per(24);
        }
      }
    }
  }

  .articleListWrap {
    padding: 30px per(17) 0;
    &__in {
      padding: 30px per(17) 40px;
      border-radius: 6px;
    }
    .articleList {
      margin: 30px 0;
    }
  }
}